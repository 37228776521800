/*-------------------------------------------

pc _index css

-------------------------------------------*/

.main_visual{
& .slider{
  width: 100%;
  height: 520px;
  & #theTarget{
    & .slide01{
      background: url(../img/top/top_slide01_pc.png) no-repeat top center;
      height: 520px;
      background-size: cover;
    }
    & .slide02{
      background: url(../img/top/top_slide02_pc.png) no-repeat top center;
      height: 520px;
      background-size: cover;
    }
    & .slide03{
      background: url(../img/top/top_slide03_pc.png) no-repeat top center;
      height: 520px;
      background-size: cover;
    }
  }
}
& .header_top{
width: 100%;
position: absolute;
top: 0;
left: 0;
z-index: 100;
& .header_inner{
  width: 900px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  & .logo{
    float: left;
  }
  & .contact_btn{
    float: right;
    background: #fff;
    opacity: 0.8;
      & a{
        color: #0071c5;
        background: url(../img/common/cercle_icon_blue.png) no-repeat top 12px right 20px;
        height: 22px;
        padding: 10px 46px 15px 20px;
        display: block;
      }
    }
  }
}
& .key_text{
  position: absolute;
  top: 300px;
  width: 100%;
  z-index: 100;
    & .wrap{
      width: 900px;
      margin: 0 auto;
    }
  }
}
.g_navi{
  position: relative;
  z-index: 10000;
  width: 100%;
  margin: 0 auto;
  height: 65px;
  background: #fff;
  padding-top: 24px;
  & .inner{
    width: 900px;
    margin: 0 auto;
  }
  & li{
    float: left;
    padding-right: 48px;
  }
  & li:last-child{
    padding-right: 0;
  }
  & a{
    width: 20px;
    color: #333333;
    text-decoration: none;
    padding-bottom: 10px;
    background: url(../img/top/top_05_pc.png) no-repeat bottom center;
  }
  & a:hover{
    color: #0071c5;
    background: url(../img/top/top_04_pc.png) no-repeat bottom center;
  }
}
  & .fixed{
    position: fixed!important;
    top: 0;
    box-shadow:5px 5px 10px rgba(0, 0, 0, 0.2);
}
#top_content{
  & section{
    margin-top: 80px;
  }
  & .inner{
    width: 900px;
    margin: 0 auto;
    margin: 50px auto 50px;
  }
  & table{
    & th{
      padding: 10px 50px 0 0;
    }
    & td{
      padding: 10px 0 0;
      & a{
        background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
        width:20%;
        height: 22px;
        padding-right: 30px;
      }
    }
    & a{
      color:#000;
    }
  }
  & .subtitle{
  background: #fff;
  border: solid 1px #cccccc;
  padding: 37px 65px;
  width: 300px;
  height: 185px;
  & .inner{
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 300px;
    }
  & .first_title{
    font-size: 40px;
    text-align: center;
  }
  & .second_title{
    text-align: center;
  }
}
.sub_text_box{
  width: 380px;
  border-bottom: solid 1px #000;
}
.first_cnt{
  border-bottom: solid 1px #000;
  margin-bottom: 70px;
  & .more_text{
    margin: 20px 0;
  }
}
.training_cnt{
  & .subtitle_bg{
  background: url(../img/top/top_01_pc.png) no-repeat;
  background-size: cover;
  height: 310px;
  }
  & .subtitle{
    float: left;
    margin-top: -125px;
    & .first_title{
    line-height: 50px;
    }
  }
  & .sub_text_box{
    float: right;
  }
}
.message_cnt{
  & .subtitle_bg{
  background: url(../img/top/top_02_pc.png) no-repeat;
  background-size: cover;
  height: 310px;
}
  & .subtitle{
    float: right;
    margin-top: -140px;
    & .inner{
      top: -25px;
    }
    & .first_title{
    line-height: 50px;
    }
  }
  & .sub_text_box{
    float: left;
  }
}
.recruitment_cnt{
  position: relative;
  & .subtitle_bg{
  background: url(../img/top/top_03_pc.png) top center no-repeat;
  background-size: cover;
  height: 640px;
}
  & .subtitle{
    position: absolute;
    top: 130px;
    width: 300px;
    height: 290px;
    right: 8%;
    padding: 37px 65px;
    & .inner{
      top: 20px;
      margin: 0!important;
    }
    & .first_title{
    line-height: 50px;
    }
  }
}
.map_cnt{
  width: 100%;
  background-color: #eaece6;
  & .trafic_links{
    position: relative;
    right: 210px;
    top: 60px;
    float: right;
  }
}
.news_cnt{
  margin: 70px 0;
  & .inner{
    border-bottom: solid 1px #000;
    border-top: solid 1px #000;
  }
  & .news_title{
    font-size: 27px;
    display: block;
  }
  & .table_ctrl{
    padding: 0 0 30px;
  }
}
.trafic_title{
  font-size: 27px;
  }
}
