/*-------------------------------------------

メッセージ

-------------------------------------------*/
body#wrap_message{
  & .links{
    & span{
      width: 48%;
      margin: 0 1%;
			box-sizing: border-box;
      border-radius: 5px;
      border: solid 1px #cccccc;
      float: left;
      margin-bottom: 20px;
      text-align: center;
      & a{
        padding: 15px 23px;
        display: block;
        color: #000;
      }
      & a:after{
        content: url(../img/common/bottom_blue_icon.png);
        padding-left: 7px;
      }
    }
  }
.page_message{
  & .page_main_visual{
    background: url(../img/message/key_img_sp.png)no-repeat;
    height: 280px;
    background-size: contain;
  }
  & .doctor_cnt{
    width: 100%;
    margin: auto;
    & .doctor{
      float: left;
      width: 43%;
      margin-left: 4%;
      margin-right: 3%;
      margin-bottom: 60px;
      & a{
        color: #000;
      }
    }
    .last{
      margin-right: 0;
    }
    & .doctor_img{
			width: auto;
      text-align: center;
      & img{
        vertical-align: bottom;
        display: block;
        margin: 0 auto;
      }
    }
    & .doctor_text_box{
    background: #fff;
    border: solid 1px #cccccc;
    padding: 10px;
      & .text{
        min-height: 130px;
      }
      & .name{
        padding-top: 20px;
        text-align: center;
      }
    }
  }
  & .name_box{
    margin-bottom: 20px;
  }
  & .category_name{
    font-size: 20px;
    border-bottom: solid 1px #000;
  }
}

.page_message_single{
	img {
		max-width: 100%;
		height: auto;
	}
  & .category{
    font-size: 15px;
    border-bottom: solid 1px #000;
  }
  & section{
    margin-bottom: 50px;
    & .left-box{
      float: left;
      width:55%;
      & h1{
        font-size: 30px;
      }
    }
    & .right-box{
      width: 40%;
      float: right;
      & img{
        max-width:100%;
      }
    }
  }
  & h3{
    font-size: 20px;
    margin-bottom: 30px;
  }
  & .pagination{
    text-align: center;
    border-bottom: solid 1px #000;
    padding-bottom: 15%;
    & .btn_std{
      display: block;
      width: 181px;
    }
    & .btn_std a{
      background: transparent;
    }
    & .btn_std.prev a{
        background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
        background-position: left 10px bottom 17px;
    }
    & .btn_std.next a{
        background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
        background-position: right 10px bottom 17px;
    }
  }
  }
}