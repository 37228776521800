/*-------------------------------------------

募集と採用

-------------------------------------------*/
#wrap_application{
  & h1{
      font-size: 35px;
      margin-bottom: 30px;
    }
    & h2{
      font-size: 35px;
      margin-bottom: 30px;
    }
    & h3{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    & h4{
      font-weight: bold;
      margin-top: 20px;
    }
  & .box{
     padding-bottom: 30px;
  }
  & .menu_application {
    background-color:#ededed;
    padding: 26px 0 16px;
    & .inner{
      margin-top: 0;
    }
    & ul {
      text-align: center;
      & li {
        display:inline-block;
        border-right:1px solid #000;
        padding: 0 37px;
        &:last-child {
          border: none;
        }
      }
    }
    & a {
      color: #000;
      &:hover {
        color: #0071c5;
      }
    }
  }
  & .btn_std{
    width: 70%;
    margin-top: 0;
  }
  & .bottom_btn{
    & .btn_std{
      width: 40%;
      margin-top: 60px;
    }
  }
  & .left_cnt{
    width: 435px;
  }

  
  /*---index---*/
  .page_index{
    & h2{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    & h3{
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 0;
    }
    & .right_cnt .inner {
      width: 465px;
      margin: 0;
      }
    & .box{
      padding-bottom: 30px;
      & li{
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px;
      }
      & ul.dot{
        & li{
          list-style-type: disc;
        }
      }
      & ul.kakko{
        & li{
          list-style:none;
          text-indent:-20px;
          margin-left: 20px;
        }
      }
    }
    & .basic_img{
      width: 250px;
      & img{
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  & .gakusyu{
    padding-bottom: 30px;
    & .box{
      padding-bottom: 0;
    }
  }
}


