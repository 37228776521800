@charset "UTF-8";

@import "component/_reset.scss";
@import "component/mixin";

@media (min-width: 768px) {
  @import "parts/pc/pc-common";
  @import "parts/pc/pc-index";
  @import "parts/pc/pc-pages";
  @import "parts/pc/pc-program";
  @import "parts/pc/pc-traning";
  @import "parts/pc/pc-application";
  @import "parts/pc/pc-message";
  @import "parts/pc/pc-others";
  @import "parts/pc/pc-module";
}

@media (max-width: 767px) {
  @import "parts/sp/sp-common";
  @import "parts/sp/sp-index";
  @import "parts/sp/sp-pages";
  @import "parts/sp/sp-program";
  @import "parts/sp/sp-traning";
  @import "parts/sp/sp-application";
  @import "parts/sp/sp-message";
  @import "parts/sp/sp-others";
  @import "parts/sp/sp-module";
}


.float_l{float:left;}
.float_r{float:right;}


.mt5{margin-top: 5px;}
.mt10{margin-top: 10px;}
.mt20{margin-top: 20px;}
.mt30{margin-top: 30px;}
.mt40{margin-top: 40px;}
.mt50{margin-top: 50px;}
.mt60{margin-top: 60px;}

.mb5{margin-bottom: 5px;}
.mb10{margin-bottom: 10px;}
.mb20{margin-bottom: 20px;}
.mb30{margin-bottom: 30px;}
.mb40{margin-bottom: 40px;}
.mb50{margin-bottom: 50px;}
.mb60{margin-bottom: 60px;}

.pr10{padding-right: 10px;}

.pb50{padding-bottom: 50px;}

.pt50{padding-top: 50px;}


/*-------------------------------------------

テキスト

-------------------------------------------*/
.text_blue{
  color: #0071c5;
}
.text_red{
  color: #FF0004;
  font-size: 14px;
}
.text_blue a{
  color: #0071c5;
}
.text_kuro{
  color: #332e2c;
}
.text_kuro a{
  color: #332e2c;
}
.font_b{
  font-weight: bold;
}



/*-------------------------------------------

カテゴリーカラー

-------------------------------------------*/
.kikaku{
  background: #fffcd8;
}



/*-------------------------------------------

コンテンツレイアウト

-------------------------------------------*/
.left_cnt{
  float: left;
}
.right_cnt{
  float: right;
}



.test{
  margin: 0;
}