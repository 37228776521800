/*-------------------------------------------

SP 共通部分

-------------------------------------------*/

@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

.sp-on {
  display: block;
}

.sp-on img {
  display: block;
}

img {
  width:100%;
}

body {
  font-family:'Noto Sans Japanese', sans-serif, "Meiryo", "メイリオ", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}
.header {
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 1000;
  & .inner {
    width:100%;
  }
  &_logo {
    padding: 0 5%;
    margin: 0 auto;
    margin-bottom: 10px;  
  }
  width:100%;
  padding-top: 10px;
  & .gNav {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 10px;
    background-color: #7f7f7e;
    opacity: 0.8;
    & .pc-on {
      display: none !important;
    }
    & li {
      height:60px;
      width:calc((100% - 5px ) / 6);
      text-align: center;
      background-color: #7f7f7e;
      border-right:1px solid #fff;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      & a {
        color: #fff;
      }
      &:last-child {
        border: none;
      }
    }
  }
  & .sp_menuBtn {
    width:94%;
    padding: 10px 3%;
    background-color: #26323c;
    color: #fff;
    &_L {
      float:left;
    }
    &_R {
      float:right;
      width:25px;
    }
  }
  & .megaMenu {
    display: none;
    width:94%;
    padding: 3%;
    background-color: #3f4d58;
    height: 480px;
    overflow: auto;
    & li {
      position: relative;
      border-bottom: 1px solid #696a66;
      & a {
        color: #fff;
        padding: 3% 2%;
        display: block;
      }
    }
    & li:after{
      position: absolute;
      content: "＞";
      position: absolute;
      right: 10px;
      top: 10px;
      color: #fff;
    }
    & .close{
      width: 10%;
      margin: 0 auto;
      margin-top: 15px;
      & .sp_menuBtn_R{
        float:none;
      }
    }
  }
}

.footer {
  padding: 30px 25px;
  background-color: #26323c;
  & .footer_inner {
    position: relative;
  }
  & a {
    color: #fff;
    padding: 9px 0 14px;
    display: block;
  }
  & .to_top {
    position: absolute;
    width:30px;
    top:-54px;
    right:0;
  }
  & .footer_menu--type2 {
    display: none;
  }
  &_menu {
    margin-bottom: 40px;
    font-size: 10px;
    & .menu--icon {
      background-image: url(../img/icon01.png);
      background-repeat: no-repeat;
      background-position: 98% 0%;
    }
  }
  &_menu li {
    border-bottom: 1px solid #696a66;
  }
  & .footer_menu--sns {
    text-align: center;
    & li {
      display: inline-block;
      width:50px;
      margin: 0 10px;
    }
  }
  & .cr_text {
    color: #fff;
    text-align: center;
    font-size: 10px;
    margin-top: 20px;
  }
}
#content{
  margin-bottom: 70px;
}
section{
  margin-top: 15%;
  & .inner{
    padding: 0 15px;
  }
}
/*
text_link
-------------------------------------------*/
.text_link{
  & a{
    color: #000;
  }
}

/*
text_icon_style
-------------------------------------------*/
.cercle_icon_blue a{
  background: url(../img/common/cercle_icon_blue.png) no-repeat right;
  height: 22px;
  padding-right: 30px;
}
.cercle_icon_kuro a{
  color: #000;
  background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
  height: 28px;
  padding-right: 30px;
}
.site_icon a{
  background: url(../img/common/icon02.png) no-repeat right;
  height: 18px;
  padding-right: 23px;
}

/*
line_decoration
-------------------------------------------*/
.line_long_01{
  width: 80px;
  border-bottom: solid 1px #000;
  float: left;
  margin-top: 15px;
}
.line_long_02{
  width: 10px;
  border-bottom: solid 1px #000;
  float: left;
  margin-top: 15px;
}
.line_long_03{
  width: 10px;
  border-bottom: solid 1px #000;
  float: left;
  margin-top: 15px;
}
.line_short_01{
  width: 10px;
  border-bottom: solid 1px #000;
  float: left;
  margin-top: 15px;
}
.line_short_02{
  width: 10px;
  border-bottom: solid 1px #000;
  float: left;
  margin-top: 15px;
}


/*
btn_style
-------------------------------------------*/
.btn_std{
  background-color: #fff;
  border: solid 1px #26323c;
  border-radius: 6px;
  width: 230px;
  margin-top: 10px;
  margin: 0 auto;
  margin-top: 15px;
  & a{
    color: #000;
    padding: 14px 10px;
    display: block;
    background: url(../img/common/cercle_icon_kuro.png) no-repeat;
    height: 22px;
    text-decoration: none;
    background-position: right 7px bottom 14px;
    }
}
.line_more{
  position: relative;
  float: right;
  background-color: #fff;
  z-index: 100;
  bottom: -10px;
  padding-left: 12px;
}
.line_more_up{
  padding-top: 20px;
}
.line_more_first{
  bottom: 30px;
}
.line_more_last{
  bottom: 12px;
}
.page_blog .line_more{
  position: relative;
  float: right;
  background-color: #fff;
  z-index: 100;
  bottom: 15px;
  padding-left: 12px;
}