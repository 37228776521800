/*-------------------------------------------

PC 共通部分

-------------------------------------------*/

@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

body {
  font-family:'Noto Sans Japanese', sans-serif, "Meiryo", "メイリオ", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
  font-size: 15px;
  line-height: 1.8;
}

.pc-on {
  display: block;
}
.header {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width:100%;
  min-width: 1280px;
  background-color: #fff;
  box-shadow: 0px 9px 3px -3px rgba(191,191,191,0.6);
  -webkit-box-shadow: 0px 9px 3px -3px rgba(191,191,191,0.6);
  -moz-box-shadow: 0px 9px 3px -3px rgba(191,191,191,0.6);
  &_logo {
    padding-top: 14px;
    margin-bottom: 30px;
  }
  & .gNav {
    text-align: center;
    padding-bottom: 16px;
    & li {
      display: inline-block;
      margin-right: 44px;
      &:last-child {
        margin-right: 0;
      }
      & a {
        color: #000;
        &:hover {
          color: #0071c5;
          text-decoration: none;
        }
      }
    }
  }
  & .megaMenu {
    display: none;
    background-color:#ededed;
    padding: 16px 0;
    & ul {
      text-align: center;
      & li {
        display:inline-block;
        border-right:1px solid #000;
        padding: 0 37px;
        &:last-child {
          border: none;
        }
      }
    }
    & a {
      color: #000;
      &:hover {
        color: #0071c5;
      }
    }
  }
  .megaMenu_top{
    width: 900px;
    margin: 0 auto;
    border-bottom: 1px dashed #a4a4a4;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
  & .sp-on {
    display: none !important;
  }
}

.footer {
  width:100%;
  min-width: 1280px;
  padding: 30px 0;
  background-color: #26323c;
  & .footer_inner{
    width: 900px;
    margin: 0 auto;
    position: relative;
  }
  & .inner {
    position: relative;
  }
  &_L {
    float:left;
    & .cr_text {
      color: #fff;
      font-size: 11px;
      margin-top: 6px;
    }
  }
  &_R {
    float:right;
    & .footer_menu--type2 {
      & li {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
  &_menu {
    & .sp-on {
      display: none !important;
    }
    & li {
      display: inline-block;
      margin-right: 30px;
      font-size: 13px;
      & a {
        color: #fff;
      }
    }
    &--sns {
      margin: 30px 0 40px 0;
    }
    &--sns li {
      display: inline-block;
      margin-right: 8px;
    }
  }
  & .to_top {
    position: absolute;
    top:-43px;
    right:0;
  }
}

#content {
  padding-bottom: 150px;
}
section{
  margin-top: 30px;
  & .inner{
  width: 900px;
  margin: 0 auto;
  }
  & .content_title{
    & h2{
      font-size: 40px;
      letter-spacing: 3px;
      }
    }
}
/*
text_link
-------------------------------------------*/
.text_link{
  & a{
    color: #000;
  }
  & a:hover{
    color: #0071c5;
  }
}

/*
text_icon_style
-------------------------------------------*/
.cercle_icon_blue a{
  background: url(../img/common/cercle_icon_blue.png) no-repeat right;
  height: 22px;
  padding-right: 30px;
}

.cercle_icon_kuro{
  & a{
    background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
    height: 22px;
    padding-right: 30px;
  }
  & a:hover{
    background: url(../img/common/cercle_icon_blue.png) no-repeat right;
    height: 22px;
    padding-right: 30px;
  }
}

.site_icon{
  & a{
    background: url(../img/common/icon02.png) no-repeat right;
    height: 18px;
    padding-right: 23px;
  }
  & a:hover{
    background: url(../img/common/icon02_blue.png) no-repeat right;
    height: 18px;
    padding-right: 23px;
  }
}

/*
btn_style
-------------------------------------------*/
.btn_std{
  width: 260px;
  margin: 0 auto;
  background-color: #fff;
  border: solid 1px #26323c;
  border-radius: 6px;
  margin-top: 20px;
}

.btn_std{
  background: #fff;
  & a{
    color: #000;
    padding: 18px 10px;
    display: block;
    background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
    height: 22px;
    text-decoration: none;
    background-position: right 7px bottom 17px;
  }
  & a:hover{
    color: #0071c5;
    padding: 18px 10px;
    display: block;
    background: url(../img/common/cercle_icon_blue.png) no-repeat right;
    height: 22px;
    text-decoration: none;
    background-position: right 7px bottom 17px;
  }
}
.btn_std:hover{
  background: #e6f4ff;
}

.line_more{
  position: relative;
  float: right;
  background-color: #fff;
  z-index: 100;
  bottom: -15px;
  padding-left: 20px;
}
.line_more_up{
  padding-top: 40px;
}
.page_blog .line_more{
  position: relative;
  float: right;
  background-color: #fff;
  z-index: 100;
  bottom: 15px;
  padding-left: 12px;
}