@charset "UTF-8";
@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

/*-----clearfix-----*/
.clearfix {
  *zoom: 1; }
  .clearfix:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden; }

/*-----common class-----*/
.mt-5 {
  margin-top: 5px; }

.mt-10 {
  margin-top: 10px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-40 {
  margin-top: 40px; }

.mb-5 {
  margin-bottom: 5px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-40 {
  margin-bottom: 40px; }

.pt-5 {
  padding-top: 5px; }

.pt-10 {
  padding-top: 10px; }

.pt-20 {
  padding-top: 20px; }

.pt-30 {
  padding-top: 30px; }

.pt-40 {
  padding-top: 40px; }

.pb-5 {
  padding-bottom: 5px; }

.pb-10 {
  padding-bottom: 10px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-40 {
  padding-bottom: 40px; }

.sp-on {
  display: none; }

.sp-on img {
  display: none; }

.pc-on {
  display: none; }

a {
  text-decoration: none; }

a img {
  transition: 0.3s; }

a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=70);
  -ms-filter: "alpha(opacity=70)"; }

a:hover {
  text-decoration: underline; }

.yu-go {
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif; }

.yu-min {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif; }

@media (min-width: 768px) {
  /*-------------------------------------------

PC 共通部分

-------------------------------------------*/
  body {
    font-family: 'Noto Sans Japanese', sans-serif, "Meiryo", "メイリオ", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
    font-size: 15px;
    line-height: 1.8; }
  .pc-on {
    display: block; }
  .header {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1280px;
    background-color: #fff;
    box-shadow: 0px 9px 3px -3px rgba(191, 191, 191, 0.6);
    -webkit-box-shadow: 0px 9px 3px -3px rgba(191, 191, 191, 0.6);
    -moz-box-shadow: 0px 9px 3px -3px rgba(191, 191, 191, 0.6); }
    .header_logo {
      padding-top: 14px;
      margin-bottom: 30px; }
    .header .gNav {
      text-align: center;
      padding-bottom: 16px; }
      .header .gNav li {
        display: inline-block;
        margin-right: 44px; }
        .header .gNav li:last-child {
          margin-right: 0; }
        .header .gNav li a {
          color: #000; }
          .header .gNav li a:hover {
            color: #0071c5;
            text-decoration: none; }
    .header .megaMenu {
      display: none;
      background-color: #ededed;
      padding: 16px 0; }
      .header .megaMenu ul {
        text-align: center; }
        .header .megaMenu ul li {
          display: inline-block;
          border-right: 1px solid #000;
          padding: 0 37px; }
          .header .megaMenu ul li:last-child {
            border: none; }
      .header .megaMenu a {
        color: #000; }
        .header .megaMenu a:hover {
          color: #0071c5; }
    .header .megaMenu_top {
      width: 900px;
      margin: 0 auto;
      border-bottom: 1px dashed #a4a4a4;
      padding-bottom: 12px;
      margin-bottom: 12px; }
    .header .sp-on {
      display: none !important; }
  .footer {
    width: 100%;
    min-width: 1280px;
    padding: 30px 0;
    background-color: #26323c; }
    .footer .footer_inner {
      width: 900px;
      margin: 0 auto;
      position: relative; }
    .footer .inner {
      position: relative; }
    .footer_L {
      float: left; }
      .footer_L .cr_text {
        color: #fff;
        font-size: 11px;
        margin-top: 6px; }
    .footer_R {
      float: right; }
      .footer_R .footer_menu--type2 li {
        display: inline-block;
        margin-left: 10px; }
    .footer_menu .sp-on {
      display: none !important; }
    .footer_menu li {
      display: inline-block;
      margin-right: 30px;
      font-size: 13px; }
      .footer_menu li a {
        color: #fff; }
    .footer_menu--sns {
      margin: 30px 0 40px 0; }
    .footer_menu--sns li {
      display: inline-block;
      margin-right: 8px; }
    .footer .to_top {
      position: absolute;
      top: -43px;
      right: 0; }
  #content {
    padding-bottom: 150px; }
  section {
    margin-top: 30px; }
    section .inner {
      width: 900px;
      margin: 0 auto; }
    section .content_title h2 {
      font-size: 40px;
      letter-spacing: 3px; }
  /*
text_link
-------------------------------------------*/
  .text_link a {
    color: #000; }
  .text_link a:hover {
    color: #0071c5; }
  /*
text_icon_style
-------------------------------------------*/
  .cercle_icon_blue a {
    background: url(../img/common/cercle_icon_blue.png) no-repeat right;
    height: 22px;
    padding-right: 30px; }
  .cercle_icon_kuro a {
    background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
    height: 22px;
    padding-right: 30px; }
  .cercle_icon_kuro a:hover {
    background: url(../img/common/cercle_icon_blue.png) no-repeat right;
    height: 22px;
    padding-right: 30px; }
  .site_icon a {
    background: url(../img/common/icon02.png) no-repeat right;
    height: 18px;
    padding-right: 23px; }
  .site_icon a:hover {
    background: url(../img/common/icon02_blue.png) no-repeat right;
    height: 18px;
    padding-right: 23px; }
  /*
btn_style
-------------------------------------------*/
  .btn_std {
    width: 260px;
    margin: 0 auto;
    background-color: #fff;
    border: solid 1px #26323c;
    border-radius: 6px;
    margin-top: 20px; }
  .btn_std {
    background: #fff; }
    .btn_std a {
      color: #000;
      padding: 18px 10px;
      display: block;
      background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
      height: 22px;
      text-decoration: none;
      background-position: right 7px bottom 17px; }
    .btn_std a:hover {
      color: #0071c5;
      padding: 18px 10px;
      display: block;
      background: url(../img/common/cercle_icon_blue.png) no-repeat right;
      height: 22px;
      text-decoration: none;
      background-position: right 7px bottom 17px; }
  .btn_std:hover {
    background: #e6f4ff; }
  .line_more {
    position: relative;
    float: right;
    background-color: #fff;
    z-index: 100;
    bottom: -15px;
    padding-left: 20px; }
  .line_more_up {
    padding-top: 40px; }
  .page_blog .line_more {
    position: relative;
    float: right;
    background-color: #fff;
    z-index: 100;
    bottom: 15px;
    padding-left: 12px; }
  /*-------------------------------------------

pc _index css

-------------------------------------------*/
  .main_visual .slider {
    width: 100%;
    height: 520px; }
    .main_visual .slider #theTarget .slide01 {
      background: url(../img/top/top_slide01_pc.png) no-repeat top center;
      height: 520px;
      background-size: cover; }
    .main_visual .slider #theTarget .slide02 {
      background: url(../img/top/top_slide02_pc.png) no-repeat top center;
      height: 520px;
      background-size: cover; }
    .main_visual .slider #theTarget .slide03 {
      background: url(../img/top/top_slide03_pc.png) no-repeat top center;
      height: 520px;
      background-size: cover; }
  .main_visual .header_top {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100; }
    .main_visual .header_top .header_inner {
      width: 900px;
      margin-right: auto;
      margin-left: auto;
      margin-top: 20px; }
      .main_visual .header_top .header_inner .logo {
        float: left; }
      .main_visual .header_top .header_inner .contact_btn {
        float: right;
        background: #fff;
        opacity: 0.8; }
        .main_visual .header_top .header_inner .contact_btn a {
          color: #0071c5;
          background: url(../img/common/cercle_icon_blue.png) no-repeat top 12px right 20px;
          height: 22px;
          padding: 10px 46px 15px 20px;
          display: block; }
  .main_visual .key_text {
    position: absolute;
    top: 300px;
    width: 100%;
    z-index: 100; }
    .main_visual .key_text .wrap {
      width: 900px;
      margin: 0 auto; }
  .g_navi {
    position: relative;
    z-index: 10000;
    width: 100%;
    margin: 0 auto;
    height: 65px;
    background: #fff;
    padding-top: 24px; }
    .g_navi .inner {
      width: 900px;
      margin: 0 auto; }
    .g_navi li {
      float: left;
      padding-right: 48px; }
    .g_navi li:last-child {
      padding-right: 0; }
    .g_navi a {
      width: 20px;
      color: #333333;
      text-decoration: none;
      padding-bottom: 10px;
      background: url(../img/top/top_05_pc.png) no-repeat bottom center; }
    .g_navi a:hover {
      color: #0071c5;
      background: url(../img/top/top_04_pc.png) no-repeat bottom center; }
  .fixed {
    position: fixed !important;
    top: 0;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); }
  #top_content section {
    margin-top: 80px; }
  #top_content .inner {
    width: 900px;
    margin: 0 auto;
    margin: 50px auto 50px; }
  #top_content table th {
    padding: 10px 50px 0 0; }
  #top_content table td {
    padding: 10px 0 0; }
    #top_content table td a {
      background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
      width: 20%;
      height: 22px;
      padding-right: 30px; }
  #top_content table a {
    color: #000; }
  #top_content .subtitle {
    background: #fff;
    border: solid 1px #cccccc;
    padding: 37px 65px;
    width: 300px;
    height: 185px; }
    #top_content .subtitle .inner {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 300px; }
    #top_content .subtitle .first_title {
      font-size: 40px;
      text-align: center; }
    #top_content .subtitle .second_title {
      text-align: center; }
  #top_content .sub_text_box {
    width: 380px;
    border-bottom: solid 1px #000; }
  #top_content .first_cnt {
    border-bottom: solid 1px #000;
    margin-bottom: 70px; }
    #top_content .first_cnt .more_text {
      margin: 20px 0; }
  #top_content .training_cnt .subtitle_bg {
    background: url(../img/top/top_01_pc.png) no-repeat;
    background-size: cover;
    height: 310px; }
  #top_content .training_cnt .subtitle {
    float: left;
    margin-top: -125px; }
    #top_content .training_cnt .subtitle .first_title {
      line-height: 50px; }
  #top_content .training_cnt .sub_text_box {
    float: right; }
  #top_content .message_cnt .subtitle_bg {
    background: url(../img/top/top_02_pc.png) no-repeat;
    background-size: cover;
    height: 310px; }
  #top_content .message_cnt .subtitle {
    float: right;
    margin-top: -140px; }
    #top_content .message_cnt .subtitle .inner {
      top: -25px; }
    #top_content .message_cnt .subtitle .first_title {
      line-height: 50px; }
  #top_content .message_cnt .sub_text_box {
    float: left; }
  #top_content .recruitment_cnt {
    position: relative; }
    #top_content .recruitment_cnt .subtitle_bg {
      background: url(../img/top/top_03_pc.png) top center no-repeat;
      background-size: cover;
      height: 640px; }
    #top_content .recruitment_cnt .subtitle {
      position: absolute;
      top: 130px;
      width: 300px;
      height: 290px;
      right: 8%;
      padding: 37px 65px; }
      #top_content .recruitment_cnt .subtitle .inner {
        top: 20px;
        margin: 0 !important; }
      #top_content .recruitment_cnt .subtitle .first_title {
        line-height: 50px; }
  #top_content .map_cnt {
    width: 100%;
    background-color: #eaece6; }
    #top_content .map_cnt .trafic_links {
      position: relative;
      right: 210px;
      top: 60px;
      float: right; }
  #top_content .news_cnt {
    margin: 70px 0; }
    #top_content .news_cnt .inner {
      border-bottom: solid 1px #000;
      border-top: solid 1px #000; }
    #top_content .news_cnt .news_title {
      font-size: 27px;
      display: block; }
    #top_content .news_cnt .table_ctrl {
      padding: 0 0 30px; }
  #top_content .trafic_title {
    font-size: 27px; }
  /*-------------------------------------------

PC 下層ページ 共通部分

-------------------------------------------*/
  .body_page {
    margin-top: 150px; }
    .body_page .header_inner {
      width: 900px;
      margin: 0 auto; }
  .breadcrumb {
    width: 900px;
    padding: 10px 0;
    margin: 0 auto; }
    .breadcrumb a {
      color: #000;
      text-decoration-line: underline; }
    .breadcrumb a:hover {
      color: #0071c5; }
  .page_main_visual {
    position: relative; }
    .page_main_visual .inner {
      width: 900px;
      margin: 0 auto; }
    .page_main_visual .page_title_box {
      position: absolute;
      top: 55px;
      width: 435px;
      border: solid 1px #cccccc; }
    .page_main_visual .item_top {
      background: #fff;
      padding: 10px 10px; }
    .page_main_visual h1 {
      font-size: 30px;
      text-align: center; }
    .page_main_visual .english_title {
      text-align: center; }
    .page_main_visual .item_bottm {
      background: #26323c;
      color: #fff;
      padding: 10px;
      min-height: 100px; }
  .page_title_only {
    font-size: 35px; }
  input, textarea {
    border: solid 1px #cccccc; }
  .layout_L {
    width: 600px;
    margin: 0 auto;
    float: left; }
  .layout_R {
    width: 200px;
    margin: 0 auto;
    float: right; }
  /*-------------------------------------------

グロナビアクティブ

-------------------------------------------*/
  /*-------------------------------------------
研修プログラム
-------------------------------------------*/
  #wrap_program {
    /*ご挨拶*/
    /*プログラムの目標*/
    /* プログラムの特徴 */
    /* 研修プログラムの概要 */
    /* 学習会・発表会 */
    /* 研修スケジュール */
    /* 研修体制 */
    /* 研修施設 */
    /* 研修医データ */
    /* 研修修了後の進路 */ }
    #wrap_program .gNav li:nth-child(1) a {
      color: #0071c5; }
    #wrap_program .active_greeting .megaMenu_top li:nth-child(1) a {
      color: #0071c5; }
    #wrap_program .active_greeting .menu_program .megaMenu_top li:nth-child(1) a {
      color: #0071c5; }
    #wrap_program .active_target .megaMenu_top li:nth-child(2) a {
      color: #0071c5; }
    #wrap_program .active_target .menu_program .megaMenu_top li:nth-child(2) a {
      color: #0071c5; }
    #wrap_program .active_feature .megaMenu_top li:nth-child(3) a {
      color: #0071c5; }
    #wrap_program .active_feature .menu_program .megaMenu_top li:nth-child(3) a {
      color: #0071c5; }
    #wrap_program .active_outline .megaMenu_top li:nth-child(4) a {
      color: #0071c5; }
    #wrap_program .active_outline .menu_program .megaMenu_top li:nth-child(4) a {
      color: #0071c5; }
    #wrap_program .active_meeting .megaMenu_top li:nth-child(5) a {
      color: #0071c5; }
    #wrap_program .active_meeting .menu_program .megaMenu_top li:nth-child(5) a {
      color: #0071c5; }
    #wrap_program .active_schedule .megaMenu_bottom li:nth-child(1) a {
      color: #0071c5; }
    #wrap_program .active_schedule .menu_program .megaMenu_bottom li:nth-child(1) a {
      color: #0071c5; }
    #wrap_program .active_system .megaMenu_bottom li:nth-child(2) a {
      color: #0071c5; }
    #wrap_program .active_system .menu_program .megaMenu_bottom li:nth-child(2) a {
      color: #0071c5; }
    #wrap_program .active_facilities .megaMenu_bottom li:nth-child(3) a {
      color: #0071c5; }
    #wrap_program .active_facilities .menu_program .megaMenu_bottom li:nth-child(3) a {
      color: #0071c5; }
    #wrap_program .active_data .megaMenu_bottom li:nth-child(4) a {
      color: #0071c5; }
    #wrap_program .active_data .menu_program .megaMenu_bottom li:nth-child(4) a {
      color: #0071c5; }
    #wrap_program .active_course .megaMenu_bottom li:nth-child(5) a {
      color: #0071c5; }
    #wrap_program .active_course .menu_program .megaMenu_bottom li:nth-child(5) a {
      color: #0071c5; }
  /*-------------------------------------------
研修科目
-------------------------------------------*/
  #wrap_traning {
    /*index*/
    /* ローテート例 */
    /*基本科目・必修科目*/
    /*選択科目*/ }
    #wrap_traning .gNav li:nth-child(3) a {
      color: #0071c5; }
    #wrap_traning .active_traning_index ul.megaMenu_single li:nth-child(1) a {
      color: #0071c5; }
    #wrap_traning .active_traning_index .menu_traning li:nth-child(1) a {
      color: #0071c5; }
    #wrap_traning .active_rotate ul.megaMenu_single li:nth-child(2) a {
      color: #0071c5; }
    #wrap_traning .active_rotate .menu_traning li:nth-child(2) a {
      color: #0071c5; }
    #wrap_traning .active_basic ul.megaMenu_single li:nth-child(3) a {
      color: #0071c5; }
    #wrap_traning .active_basic .menu_traning li:nth-child(3) a {
      color: #0071c5; }
    #wrap_traning .active_select ul.megaMenu_single li:nth-child(4) a {
      color: #0071c5; }
    #wrap_traning .active_select .menu_traning li:nth-child(4) a {
      color: #0071c5; }
  /*-------------------------------------------
医学生へのメッセージ
-------------------------------------------*/
  #wrap_message .gNav li:nth-child(4) a {
    color: #0071c5; }
  /*-------------------------------------------
募集と採用
-------------------------------------------*/
  #wrap_application {
    /*病院実習・見学について*/
    /*研修医の処遇*/
    /*募集要項*/
    /*研修中断について*/ }
    #wrap_application .gNav li:nth-child(5) a {
      color: #0071c5; }
    #wrap_application .active_application_index ul.megaMenu_single li:nth-child(1) a {
      color: #0071c5; }
    #wrap_application .active_application_index .menu_application li:nth-child(1) a {
      color: #0071c5; }
    #wrap_application .active_treatment ul.megaMenu_single li:nth-child(2) a {
      color: #0071c5; }
    #wrap_application .active_treatment .menu_application li:nth-child(2) a {
      color: #0071c5; }
    #wrap_application .active_about ul.megaMenu_single li:nth-child(3) a {
      color: #0071c5; }
    #wrap_application .active_about .menu_application li:nth-child(3) a {
      color: #0071c5; }
    #wrap_application .active_interruption ul.megaMenu_single li:nth-child(4) a {
      color: #0071c5; }
    #wrap_application .active_interruption .menu_application li:nth-child(4) a {
      color: #0071c5; }
  /*-------------------------------------------
お申し込み・お問い合わせ
-------------------------------------------*/
  #wrap_contact .gNav li:nth-child(6) a {
    color: #0071c5; }
  /*-------------------------------------------
アクセス
-------------------------------------------*/
  #wrap_access .gNav li:nth-child(7) a {
    color: #0071c5; }
  /*-------------------------------------------

h1ボーダー

-------------------------------------------*/
  .change-border-color {
    position: relative; }
  .change-border-color::before {
    border-bottom: solid 2px #0071c5;
    width: 15%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    content: ""; }
  .change-border-color::after {
    border-bottom: solid 2px #cccccc;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    content: ""; }
  /*-------------------------------------------

研修プログラム

-------------------------------------------*/
  #wrap_program {
    /*---ご挨拶---*/
    /*---プログラムの目標---*/
    /*---学習会・勉強会---*/
    /*---プログラムの特徴---*/
    /*---プログラムの概要---*/
    /*--- 研修スケジュール ---*/
    /*--- 研修体制 ---*/
    /*--- 研修施設 ---*/
    /*--- 研修医データ ---*/
    /*--- 研修修了後の進路 ---*/ }
    #wrap_program h2 {
      font-size: 35px;
      margin-bottom: 30px; }
    #wrap_program table th {
      vertical-align: top; }
    #wrap_program table td {
      padding: 0 0 20px 5px; }
    #wrap_program .box {
      padding-bottom: 30px; }
    #wrap_program .menu_program {
      background-color: #ededed;
      padding: 16px 0; }
      #wrap_program .menu_program ul {
        text-align: center; }
        #wrap_program .menu_program ul li {
          display: inline-block;
          border-right: 1px solid #000;
          padding: 0 37px; }
          #wrap_program .menu_program ul li:last-child {
            border: none; }
        #wrap_program .menu_program ul:first-child {
          border-bottom: 1px dashed #a4a4a4;
          padding-bottom: 12px;
          margin-bottom: 12px; }
      #wrap_program .menu_program a {
        color: #000; }
        #wrap_program .menu_program a:hover {
          color: #0071c5; }
    #wrap_program .page_greeting h1 {
      font-size: 35px;
      margin-bottom: 30px; }
    #wrap_program .page_greeting .left_cnt img {
      width: 270px; }
    #wrap_program .page_greeting .right_cnt .inner {
      width: 590px;
      margin: 0; }
    #wrap_program .page_greeting .right_cnt .name {
      font-weight: bold;
      font-size: 25px; }
      #wrap_program .page_greeting .right_cnt .name span {
        font-size: 35px; }
    #wrap_program .page_greeting .right_cnt .title {
      font-size: 25px;
      font-weight: bold;
      margin-top: 35px;
      line-height: 36px; }
    #wrap_program .page_greeting .right_cnt .text_deco {
      margin-top: 25px;
      border-bottom: solid 1px #000;
      padding-bottom: 50px; }
    #wrap_program .page_greeting .box {
      margin-bottom: 50px; }
    #wrap_program .page_target .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_meeting .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_meeting .box img {
      width: 95%; }
    #wrap_program .page_feature .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_feature .inner .box ol {
      list-style: decimal;
      margin: 0px 0px 0px 20px; }
    #wrap_program .page_feature .inner .box ol li {
      line-height: 2;
      margin-bottom: 40px; }
    #wrap_program .page_feature .inner .box ol.sm-space li {
      line-height: 2;
      margin-bottom: 0px; }
    #wrap_program .page_feature .inner .img-holder {
      margin-top: 40px;
      margin-bottom: 84px; }
    #wrap_program .page_outline .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_outline .inner dl {
      display: table;
      table-layout: fixed;
      width: 100%;
      border: 1px solid #ebebeb;
      border-right: 0px; }
      #wrap_program .page_outline .inner dl dt, #wrap_program .page_outline .inner dl dd {
        display: table-cell;
        padding: 30px;
        border-right: 1px solid #ebebeb; }
      #wrap_program .page_outline .inner dl dt {
        width: 30%;
        text-align: center;
        vertical-align: middle; }
      #wrap_program .page_outline .inner dl dd {
        width: 70%; }
    #wrap_program .page_outline .inner span.divider:after {
      content: url("../img/program/icon-arrow-down.png");
      display: block;
      position: relative;
      width: 52px;
      margin: 30px auto; }
    #wrap_program .page_outline .inner ul {
      margin: 0px 0px 0px 2%;
      float: left;
      width: 46%; }
      #wrap_program .page_outline .inner ul li {
        list-style: disc; }
    #wrap_program .page_training_schedule .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_system .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_system section p {
      line-height: 2; }
    #wrap_program .page_system section .img-holder {
      margin-top: 23px; }
    #wrap_program .page_system section .left-box {
      width: 48%;
      float: left; }
    #wrap_program .page_system section .right-box {
      width: 48%;
      float: right; }
    #wrap_program .page_facilities .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_facilities section p {
      line-height: 3; }
    #wrap_program .page_facilities table {
      border-bottom: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table th {
        width: 120px;
        background: #e6f4ff;
        font-size: 18px;
        text-align: center;
        vertical-align: middle; }
      #wrap_program .page_facilities table td {
        vertical-align: middle; }
      #wrap_program .page_facilities table th.bunya {
        padding: 33px 47px;
        vertical-align: middle;
        border-bottom: solid 2px #aaaaaa;
        border-right: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table th.name {
        padding: 15px;
        width: 563px;
        border-bottom: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table th.kikan {
        padding: 33px 27px;
        border-bottom: solid 2px #aaaaaa;
        border-left: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table .bottom_title td {
        background: #e6f4ff;
        text-align: center;
        border-bottom: solid 2px #0071c5;
        width: 296px;
        padding: 10px 0; }
      #wrap_program .page_facilities table .bottom_title .minato {
        border-right: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table .all {
        border-bottom: dotted 1px #aaaaaa; }
        #wrap_program .page_facilities table .all th {
          border-right: solid 1px #aaaaaa; }
        #wrap_program .page_facilities table .all td {
          padding: 10px 17px; }
        #wrap_program .page_facilities table .all .hospital_name {
          border-right: solid 1px #aaaaaa; }
        #wrap_program .page_facilities table .all .links {
          border-right: solid 1px #aaaaaa; }
        #wrap_program .page_facilities table .all a {
          color: #000; }
        #wrap_program .page_facilities table .all a:hover {
          color: #0071c5; }
      #wrap_program .page_facilities table .style01 .field {
        background: #f9f1e5; }
      #wrap_program .page_facilities table .style02 .field {
        background: #f0e4d3; }
      #wrap_program .page_facilities table .style02 td {
        background: #ededed; }
      #wrap_program .page_facilities table .choice {
        width: 50px;
        border-right: solid 1px #aaaaaa; }
    #wrap_program .page_data .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_course .page_main_visual {
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_program .page_course section .img-holder {
      margin-bottom: 118px; }
  /*-------------------------------------------

研修科目

-------------------------------------------*/
  #wrap_traning {
    /*---index---*/
    /*---基本科目・必修科目---*/
    /*---選択科目---*/
    /* page rotate */ }
    #wrap_traning h2 {
      font-size: 35px;
      margin-bottom: 30px; }
    #wrap_traning table th {
      vertical-align: top; }
    #wrap_traning table td {
      padding: 0 0 20px 5px; }
    #wrap_traning .box {
      padding-bottom: 30px; }
    #wrap_traning .menu_traning {
      background-color: #ededed;
      padding: 16px 0; }
      #wrap_traning .menu_traning .inner {
        margin-top: 0; }
      #wrap_traning .menu_traning ul {
        text-align: center; }
        #wrap_traning .menu_traning ul li {
          display: inline-block;
          border-right: 1px solid #000;
          padding: 0 37px; }
          #wrap_traning .menu_traning ul li:last-child {
            border: none; }
      #wrap_traning .menu_traning a {
        color: #000; }
        #wrap_traning .menu_traning a:hover {
          color: #0071c5; }
    #wrap_traning .links span {
      border-radius: 5px;
      border: solid 1px #cccccc;
      float: left;
      margin-right: 20px;
      margin-bottom: 20px; }
      #wrap_traning .links span a {
        padding: 3px 30px;
        display: block;
        color: #000; }
      #wrap_traning .links span a:after {
        content: url(../img/common/bottom_blue_icon.png);
        padding-left: 7px; }
    #wrap_traning section .inner {
      margin-top: 50px; }
    #wrap_traning .page_index .page_main_visual {
      background: url(../img/traning/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    #wrap_traning .page_basic h1 {
      font-size: 35px;
      margin-bottom: 30px; }
    #wrap_traning .page_basic h2 {
      font-size: 30px;
      margin-bottom: 30px; }
    #wrap_traning .page_basic h3 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold; }
    #wrap_traning .page_basic h4 {
      font-weight: bold;
      margin-top: 20px; }
    #wrap_traning .page_basic .right_cnt .inner {
      width: 465px;
      margin: 0; }
    #wrap_traning .page_basic .box {
      padding-bottom: 30px; }
      #wrap_traning .page_basic .box li {
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px; }
      #wrap_traning .page_basic .box ul.dot li {
        list-style-type: disc; }
      #wrap_traning .page_basic .box ul.kakko li {
        list-style: none;
        text-indent: -20px;
        margin-left: 20px; }
    #wrap_traning .page_basic .basic_img {
      width: 386px; }
      #wrap_traning .page_basic .basic_img img {
        margin-bottom: 20px; }
    #wrap_traning .page_basic #basic_01, #wrap_traning .page_basic #basic_02, #wrap_traning .page_basic #basic_03, #wrap_traning .page_basic #basic_04, #wrap_traning .page_basic #basic_05, #wrap_traning .page_basic #basic_06, #wrap_traning .page_basic #basic_07, #wrap_traning .page_basic #basic_08, #wrap_traning .page_basic #select_01, #wrap_traning .page_basic #select_02, #wrap_traning .page_basic #select_03, #wrap_traning .page_basic #select_04, #wrap_traning .page_basic #select_05, #wrap_traning .page_basic #select_06, #wrap_traning .page_basic #select_07, #wrap_traning .page_basic #select_08, #wrap_traning .page_basic #select_09, #wrap_traning .page_basic #select_10 {
      margin-top: -150px;
      padding-top: 150px; }
    #wrap_traning .traning_top {
      width: 900px;
      margin: 0 auto;
      clear: both;
      border-bottom: solid 1px #000; }
    #wrap_traning .page_rotate h1 {
      font-size: 35px;
      margin-bottom: 47px; }
    #wrap_traning .page_rotate h2 {
      font-size: 25px; }
  /*-------------------------------------------

募集と採用

-------------------------------------------*/
  #wrap_application {
    /*---index---*/ }
    #wrap_application h1 {
      font-size: 35px;
      margin-bottom: 30px; }
    #wrap_application h2 {
      font-size: 35px;
      margin-bottom: 30px; }
    #wrap_application h3 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold; }
    #wrap_application h4 {
      font-weight: bold;
      margin-top: 20px; }
    #wrap_application .box {
      padding-bottom: 30px; }
    #wrap_application .menu_application {
      background-color: #ededed;
      padding: 26px 0 16px; }
      #wrap_application .menu_application .inner {
        margin-top: 0; }
      #wrap_application .menu_application ul {
        text-align: center; }
        #wrap_application .menu_application ul li {
          display: inline-block;
          border-right: 1px solid #000;
          padding: 0 37px; }
          #wrap_application .menu_application ul li:last-child {
            border: none; }
      #wrap_application .menu_application a {
        color: #000; }
        #wrap_application .menu_application a:hover {
          color: #0071c5; }
    #wrap_application .btn_std {
      width: 70%;
      margin-top: 0; }
    #wrap_application .bottom_btn .btn_std {
      width: 40%;
      margin-top: 60px; }
    #wrap_application .left_cnt {
      width: 435px; }
    #wrap_application .page_index h2 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold; }
    #wrap_application .page_index h3 {
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 0; }
    #wrap_application .page_index .right_cnt .inner {
      width: 465px;
      margin: 0; }
    #wrap_application .page_index .box {
      padding-bottom: 30px; }
      #wrap_application .page_index .box li {
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px; }
      #wrap_application .page_index .box ul.dot li {
        list-style-type: disc; }
      #wrap_application .page_index .box ul.kakko li {
        list-style: none;
        text-indent: -20px;
        margin-left: 20px; }
    #wrap_application .page_index .basic_img {
      width: 250px; }
      #wrap_application .page_index .basic_img img {
        width: 100%;
        margin-bottom: 20px; }
    #wrap_application .gakusyu {
      padding-bottom: 30px; }
      #wrap_application .gakusyu .box {
        padding-bottom: 0; }
  /*-------------------------------------------

メッセージ

-------------------------------------------*/
  body#wrap_message .links span {
    border-radius: 5px;
    border: solid 1px #cccccc;
    float: left;
    margin-right: 16px;
    margin-bottom: 20px; }
    body#wrap_message .links span a {
      padding: 10px 32px;
      display: block;
      color: #000; }
    body#wrap_message .links span a:after {
      content: url(../img/common/bottom_blue_icon.png);
      padding-left: 7px; }
  body#wrap_message .links span:nth-child(5) {
    margin-right: 0; }
  body#wrap_message .page_message .page_main_visual {
    background: url(../img/message/key_img_pc.png) no-repeat;
    height: 316px;
    background-size: cover; }
  body#wrap_message .page_message .doctor_cnt {
    margin-bottom: 100px; }
    body#wrap_message .page_message .doctor_cnt .doctor {
      float: left;
      width: 258px;
      margin-left: 21px;
      margin-right: 21px; }
      body#wrap_message .page_message .doctor_cnt .doctor a {
        color: #000; }
    body#wrap_message .page_message .doctor_cnt .last {
      margin-right: 0; }
    body#wrap_message .page_message .doctor_cnt .doctor_img {
      height: 286px;
      text-align: center; }
      body#wrap_message .page_message .doctor_cnt .doctor_img img {
        vertical-align: bottom;
        display: block;
        margin: 0 auto; }
    body#wrap_message .page_message .doctor_cnt .doctor_text_box {
      background: #fff;
      border: solid 1px #cccccc;
      padding: 10px; }
      body#wrap_message .page_message .doctor_cnt .doctor_text_box .text {
        min-height: 100px; }
      body#wrap_message .page_message .doctor_cnt .doctor_text_box .name {
        padding-top: 20px;
        text-align: center; }
  body#wrap_message .page_message .name_box {
    margin-bottom: 50px; }
  body#wrap_message .page_message .category_name {
    font-size: 25px;
    border-bottom: solid 1px #000; }
  body#wrap_message .page_message_single img {
    max-width: 100%;
    height: auto; }
  body#wrap_message .page_message_single .category {
    font-size: 20px;
    border-bottom: solid 1px #000; }
  body#wrap_message .page_message_single section .left-box {
    float: left;
    width: 40%; }
    body#wrap_message .page_message_single section .left-box h1 {
      font-size: 50px; }
  body#wrap_message .page_message_single section .right-box {
    float: right; }
  body#wrap_message .page_message_single h3 {
    font-size: 20px;
    margin-bottom: 30px; }
  body#wrap_message .page_message_single .pagination {
    text-align: center;
    border-bottom: solid 1px #000;
    padding-bottom: 30px; }
    body#wrap_message .page_message_single .pagination .btn_std {
      display: inline-block;
      width: 181px; }
    body#wrap_message .page_message_single .pagination .btn_std a {
      background: transparent; }
    body#wrap_message .page_message_single .pagination .btn_std.prev {
      margin-right: 20px; }
    body#wrap_message .page_message_single .pagination .btn_std.next {
      margin-left: 20px; }
    body#wrap_message .page_message_single .pagination .btn_std.prev a {
      background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
      background-position: left 10px bottom 17px; }
    body#wrap_message .page_message_single .pagination .btn_std.next a {
      background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
      background-position: right 10px bottom 17px; }
  body#wrap_message #message01, body#wrap_message #message02, body#wrap_message #message03, body#wrap_message #message04, body#wrap_message #message05 {
    margin-top: -150px;
    padding-top: 150px; }
  /*-------------------------------------------

初期・後期振り分けページ

-------------------------------------------*/
  #wrap_furiwake {
    width: 100%; }
    #wrap_furiwake header .inner {
      width: 900px;
      margin: 0 auto;
      padding: 20px 0; }
      #wrap_furiwake header .inner img.pc-on {
        margin: 0 auto;
        display: block; }
    #wrap_furiwake #content {
      min-width: 1280px;
      width: 100%;
      padding-bottom: 0px; }
      #wrap_furiwake #content section .inner {
        position: relative;
        width: 100%; }
        #wrap_furiwake #content section .inner * {
          box-sizing: border-box; }
        #wrap_furiwake #content section .inner .left_box {
          max-height: 700px;
          height: 700px;
          float: left;
          width: 50%;
          padding-top: 15%;
          text-align: center;
          background-size: cover;
          background-image: url("../img/furiwake/furiwake_bg01_pc.png"); }
        #wrap_furiwake #content section .inner .right_box {
          max-height: 700px;
          height: 700px;
          float: left;
          width: 50%;
          padding-top: 15%;
          text-align: center;
          background-size: cover;
          background-image: url("../img/furiwake/furiwake_bg02_pc.png"); }
        #wrap_furiwake #content section .inner div a {
          font-size: 40px;
          color: #000000;
          text-decoration: none;
          padding: 70px 0;
          text-align: center;
          display: block;
          margin: 0 auto;
          background-color: rgba(255, 255, 255, 0.66);
          border: 1px solid #ebebeb;
          border-right: 0px;
          max-width: 599px;
          width: 100%; }
        #wrap_furiwake #content section .inner div a:hover {
          color: #0071c5;
          background-color: #e6f4ff;
          opacity: 0.7; }
    #wrap_furiwake footer .footer_L {
      float: none;
      text-align: center; }
    #wrap_furiwake footer .footer_R {
      float: none;
      text-align: center; }
  /*-------------------------------------------

お申し込み・お問い合わせ

-------------------------------------------*/
  .contactform {
    margin-top: 30px; }
    .contactform .min {
      font-size: 14px; }
    .contactform .form_cnt {
      border-bottom: solid 1px #000;
      padding: 40px 0; }
      .contactform .form_cnt .group {
        padding-bottom: 20px; }
      .contactform .form_cnt .category_neme {
        font-size: 20px;
        display: block;
        padding: 0 0 10px; }
      .contactform .form_cnt .category_neme_ctrl {
        padding: 30px 0 0; }
      .contactform .form_cnt .form-control {
        width: 90%;
        height: 30px;
        padding: 6px 12px; }
      .contactform .form_cnt .ctrl {
        float: left;
        margin-right: 20px; }
    .contactform .date {
      float: left;
      margin-right: 10px; }
    .contactform .date_text_ctrl {
      margin-top: -4px;
      float: left;
      margin-right: 10px; }
    .contactform .checkbox_ctrl {
      margin-right: 20px;
      float: left; }
    .contactform .textarea_ctrl {
      width: 100%;
      height: 140px; }
    .contactform .bottom_box {
      margin: 70px 0 0; }
    .contactform .agree_cnt {
      text-align: center;
      margin: 0 0 20px; }
    .contactform .btn_std {
      margin: 0 auto;
      text-align: center; }
      .contactform .btn_std a {
        background: none; }
    .contactform .reset_text {
      text-align: center;
      margin-top: 20px; }
      .contactform .reset_text a {
        color: #000;
        background: #e5e5e5;
        padding: 5px 20px; }
      .contactform .reset_text a:hover {
        text-decoration: underline; }
  .thanks_cnt .inner {
    min-height: 500px; }
  .thanks_title {
    font-size: 35px;
    text-align: center; }
  .thanks_text {
    text-align: center;
    margin-bottom: 70px; }
  .error_message {
    color: #ff0004;
    display: none; }
  .error,
  #form_grade_error,
  .agree_error {
    color: #FF0004;
    font-size: 15px; }
  .agree_error {
    display: none; }
  .send_button {
    -webkit-appearance: none;
    width: 260px;
    margin: 0 auto;
    background-color: #fff;
    display: block;
    border-radius: 5px;
    line-height: 30px;
    cursor: pointer; }
  .send_button:hover {
    -webkit-appearance: none;
    width: 260px;
    color: #0071c5;
    margin: 0 auto;
    display: block;
    border-radius: 5px;
    line-height: 30px;
    cursor: pointer;
    background: #e6f4ff; }
  /*-------------------------------------------

お知らせ

-------------------------------------------*/
  #wrap_blog {
    /* single */ }
    #wrap_blog .blog_cnt {
      padding-top: 40px; }
      #wrap_blog .blog_cnt .date {
        float: left;
        margin-right: 20px;
        font-size: 18px; }
      #wrap_blog .blog_cnt .category {
        padding: 3px 30px;
        border-radius: 5px; }
      #wrap_blog .blog_cnt .blog_title {
        font-size: 25px;
        margin: 20px 0; }
      #wrap_blog .blog_cnt .blog_text {
        margin-top: 20px;
        border-bottom: solid 1px #000;
        padding-bottom: 40px; }
      #wrap_blog .blog_cnt .line_more a {
        color: #000; }
      #wrap_blog .blog_cnt .line_more a:hover {
        color: #0071c5; }
    #wrap_blog .layout_R .category {
      border-top: solid 1px #000; }
      #wrap_blog .layout_R .category li {
        border-bottom: dotted 1px #000;
        padding: 10px 0 10px 15px; }
        #wrap_blog .layout_R .category li a {
          color: #000;
          display: block; }
      #wrap_blog .layout_R .category li:hover {
        background: url(../img/common/migi_blue_icon.png) no-repeat left;
        position: relative; }
    #wrap_blog .pagenation {
      margin-top: 120px;
      text-align: center; }
      #wrap_blog .pagenation .prev {
        background: none;
        color: #26323c; }
      #wrap_blog .pagenation .next {
        background: none; }
      #wrap_blog .pagenation .nav-links {
        display: inline-block; }
      #wrap_blog .pagenation .current {
        float: left;
        margin-right: 6px;
        padding: 5px 14px;
        color: #fff;
        background: #26323c;
        display: block; }
      #wrap_blog .pagenation a {
        float: left;
        background: #bfbfbf;
        margin-right: 6px;
        padding: 5px 14px;
        color: #26323c;
        display: block; }
      #wrap_blog .pagenation a:hover {
        color: #fff;
        background: #26323c; }
    #wrap_blog .page_blog_single img {
      max-width: 100%;
      height: auto; }
    #wrap_blog .page_blog_single .blog_cnt img {
      margin: 0 auto;
      display: block;
      margin-bottom: 30px; }
    #wrap_blog .page_blog_single .pagination {
      text-align: center; }
      #wrap_blog .page_blog_single .pagination .btn_std {
        display: inline-block;
        width: 181px; }
      #wrap_blog .page_blog_single .pagination .btn_std a {
        background: transparent; }
      #wrap_blog .page_blog_single .pagination .btn_std.prev {
        margin-right: 20px; }
      #wrap_blog .page_blog_single .pagination .btn_std.next {
        margin-left: 20px; }
      #wrap_blog .page_blog_single .pagination .btn_std.prev a {
        background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
        background-position: left 10px bottom 17px; }
      #wrap_blog .page_blog_single .pagination .btn_std.next a {
        background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
        background-position: right 10px bottom 17px; }
    #wrap_blog .page_blog_single .sns_box {
      margin-top: 17px;
      margin-bottom: 64px; }
      #wrap_blog .page_blog_single .sns_box ul {
        margin: 0px; }
        #wrap_blog .page_blog_single .sns_box ul li {
          display: inline-block;
          line-height: 1; }
  /* ----------------access------------------ */
  body#wrap_access section {
    margin-bottom: 60px; }
  body#wrap_access h1 {
    font-size: 35px;
    margin-bottom: 23px; }
  body#wrap_access h2 {
    font-size: 25px;
    margin-bottom: 23px; }
  body#wrap_access .page_access .map-box .left-box {
    float: left;
    width: 60%; }
  body#wrap_access .page_access .map-box .right-box {
    float: right;
    width: 100%;
    max-width: 290px; }
    body#wrap_access .page_access .map-box .right-box .enlarge {
      border-radius: 5px;
      border: solid 1px #cccccc;
      display: block;
      padding-left: 55px;
      max-width: 254px;
      width: 100%;
      box-sizing: border-box;
      margin: 20px 0px; }
      body#wrap_access .page_access .map-box .right-box .enlarge a {
        color: #000000; }
      body#wrap_access .page_access .map-box .right-box .enlarge a:before {
        content: url(../img/common/bottom_blue_icon.png);
        padding-right: 7px; }
  body#wrap_access #subway, body#wrap_access #bus {
    margin-top: -150px;
    padding-top: 150px; }
  /*-------------------------------------------

個人情報の取扱について

-------------------------------------------*/
  #wrap_privacy h1 {
    font-size: 35px; }
  #wrap_privacy h2 {
    font-size: 20px;
    font-weight: bold; }
  #wrap_privacy .last_name {
    float: right;
    text-align: right;
    margin-top: 30px; }
  /*-------------------------------------------

エラー画面

-------------------------------------------*/
  #wrap_error .inner_ctrl {
    padding-top: 50px;
    text-align: center; }
  #wrap_error .not_found {
    font-size: 70px;
    color: #666666; }
  #wrap_error .sorry {
    font-size: 35px;
    margin-top: 20px; }
  #wrap_error .btn_std {
    margin-top: 70px; }
  /*-------------------------------------------

モジュール

-------------------------------------------*/
  .body_module {
    /*-------------------------------------------
title
-------------------------------------------*/
    /*-------------------------------------------
list
-------------------------------------------*/
    /*-------------------------------------------
layout
-------------------------------------------*/
    /*-------------------------------------------
blog
-------------------------------------------*/
    /*-------------------------------------------
message
-------------------------------------------*/
    /*-------------------------------------------
お申し込み・お問い合わせ
-------------------------------------------*/
    /*-------------------------------------------
btn
-------------------------------------------*/
    /*-------------------------------------------
pagination
-------------------------------------------*/ }
    .body_module .page_main_visual {
      background: url(../img/traning/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover; }
    .body_module .menu_module {
      background-color: #ededed;
      padding: 16px 0; }
    .body_module .menu_module .inner {
      margin-top: 0; }
    .body_module .menu_module ul {
      text-align: center; }
    .body_module .module_title {
      font-size: 25px;
      padding: 10px;
      background: #f6b37f;
      color: #000;
      margin-bottom: 20px;
      margin-top: 50px; }
    .body_module .menu_module ul li {
      display: inline-block;
      border-right: 1px solid #000;
      padding: 0 37px; }
    .body_module .menu_module a {
      color: #000; }
    .body_module .menu_module a:hover {
      color: #0071c5; }
    .body_module .menu_module ul li:last-child {
      border: none; }
    .body_module .module_box_title {
      font-size: 20px;
      color: #ff0000;
      border-bottom: solid 1px #ff0000;
      padding-bottom: 10px;
      margin-top: 50px;
      margin-bottom: 20px; }
    .body_module h1 {
      font-size: 30px; }
    .body_module h2 {
      font-size: 25px; }
    .body_module .center_box {
      text-align: center; }
    .body_module .title_large {
      font-size: 25px;
      margin-bottom: 30px; }
    .body_module .title_midium {
      font-size: 20px;
      margin-bottom: 30px; }
    .body_module .title_midium_style02 {
      margin-bottom: 0; }
    .body_module .sub_title {
      margin-bottom: 30px; }
    .body_module .text_center {
      text-align: center; }
    .body_module .text_right {
      text-align: right; }
    .body_module .dot li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 20px; }
    .body_module .nomal li {
      list-style: decimal;
      list-style-position: outside;
      margin-left: 20px; }
    .body_module .kakko li {
      list-style: none;
      text-indent: -20px;
      margin-left: 20px; }
    .body_module .layout_R .category {
      border-top: solid 1px #000; }
    .body_module .layout_R .category li {
      border-bottom: dotted 1px #000;
      padding: 10px 0 10px 15px; }
    .body_module .layout_R .category li:hover {
      background: url(../img/common/migi_blue_icon.png) no-repeat left;
      position: relative; }
    .body_module .layout_R .category li a {
      color: #000;
      display: block; }
    .body_module .blog_cnt {
      padding-top: 40px; }
    .body_module .blog_cnt .date {
      float: left;
      margin-right: 20px;
      font-size: 18px; }
    .body_module .blog_cnt .category {
      padding: 3px 30px;
      border-radius: 5px; }
    .body_module .blog_cnt .blog_title {
      font-size: 25px !important;
      margin: 20px 0 !important; }
    .body_module .line_more {
      position: relative;
      float: right;
      background-color: #fff;
      z-index: 100;
      bottom: 15px;
      padding-left: 12px; }
    .body_module .line_more a {
      color: #000; }
    .body_module .blog_cnt .blog_text {
      margin-top: 20px;
      border-bottom: solid 1px #000;
      padding-bottom: 40px; }
    .body_module .cercle_icon_kuro a {
      background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
      height: 22px;
      padding-right: 30px; }
    .body_module .name_box {
      margin-bottom: 50px; }
    .body_module .category_name {
      font-size: 25px;
      border-bottom: solid 1px #000; }
    .body_module .doctor_cnt {
      margin-bottom: 100px; }
    .body_module .doctor_cnt .doctor {
      float: left;
      width: 258px;
      margin-left: 21px;
      margin-right: 21px; }
    .body_module .doctor_cnt .doctor a {
      color: #000; }
    .body_module .doctor_cnt .doctor_img img {
      vertical-align: bottom;
      display: block;
      margin: 0 auto; }
    .body_module .doctor_cnt .doctor_text_box {
      background: #fff;
      border: solid 1px #cccccc;
      padding: 10px; }
    .body_module .doctor_cnt .doctor_text_box .text {
      min-height: 100px; }
    .body_module .doctor_cnt .doctor_text_box .name {
      padding-top: 20px;
      text-align: center; }
    .body_module .contactform {
      margin-top: 30px; }
      .body_module .contactform .min {
        font-size: 14px; }
      .body_module .contactform .form_cnt {
        border-bottom: solid 1px #000;
        padding: 40px 0; }
        .body_module .contactform .form_cnt .group {
          padding-bottom: 20px; }
        .body_module .contactform .form_cnt .category_neme {
          font-size: 20px;
          display: block;
          padding: 0 0 10px; }
        .body_module .contactform .form_cnt .category_neme_ctrl {
          padding: 30px 0 0; }
        .body_module .contactform .form_cnt .form-control {
          width: 90%;
          height: 30px;
          padding: 6px 12px; }
        .body_module .contactform .form_cnt .ctrl {
          float: left;
          margin-right: 20px; }
      .body_module .contactform .date {
        float: left;
        margin-right: 10px; }
      .body_module .contactform .date_text_ctrl {
        margin-top: -4px;
        float: left;
        margin-right: 10px; }
      .body_module .contactform .checkbox_ctrl {
        margin-right: 20px;
        float: left; }
      .body_module .contactform .textarea_ctrl {
        width: 100%;
        height: 140px; }
      .body_module .contactform .bottom_box {
        margin: 70px 0 0; }
      .body_module .contactform .agree_cnt {
        text-align: center;
        margin: 0 0 20px; }
      .body_module .contactform .btn_std {
        margin: 0 auto;
        text-align: center; }
      .body_module .contactform .btn_std a:hover {
        color: #0071c5;
        padding: 18px 10px;
        display: block;
        background: url(../img/common/cercle_icon_blue.png) no-repeat right;
        height: 22px;
        text-decoration: none;
        background-position: right 7px bottom 17px; }
      .body_module .contactform .btn_std:hover {
        background: #e6f4ff; }
      .body_module .contactform .reset_text {
        text-align: center;
        margin-top: 20px; }
        .body_module .contactform .reset_text a {
          color: #000;
          background: #e5e5e5;
          padding: 5px 20px; }
        .body_module .contactform .reset_text a:hover {
          text-decoration: underline; }
    .body_module .thanks_cnt .inner {
      min-height: 500px; }
    .body_module .thanks_title {
      font-size: 35px;
      text-align: center; }
    .body_module .thanks_text {
      text-align: center;
      margin-bottom: 70px; }
    .body_module .error_message {
      color: #ff0004;
      display: none; }
    .body_module .error,
    .body_module #form_grade_error,
    .body_module .agree_error {
      color: #FF0004;
      font-size: 15px; }
    .body_module .agree_error {
      display: none; }
    .body_module .send_button {
      -webkit-appearance: none;
      width: 260px;
      margin: 0 auto;
      background-color: #fff;
      display: block;
      border-radius: 5px;
      line-height: 30px;
      cursor: pointer; }
    .body_module .send_button:hover {
      -webkit-appearance: none;
      width: 260px;
      color: #0071c5;
      margin: 0 auto;
      display: block;
      border-radius: 5px;
      line-height: 30px;
      cursor: pointer;
      background: #e6f4ff; }
    .body_module .agree_error {
      color: #FF0004;
      font-size: 15px; }
    .body_module .contactform .agree_cnt {
      text-align: center;
      margin: 0 0 20px; }
    .body_module .defo_link a {
      color: #000;
      text-decoration: underline; }
    .body_module .links span {
      border-radius: 5px;
      border: solid 1px #cccccc;
      float: left;
      margin-right: 20px;
      margin-bottom: 20px; }
      .body_module .links span a {
        padding: 3px 30px;
        display: block;
        color: #000; }
      .body_module .links span a:after {
        content: url(../img/common/bottom_blue_icon.png);
        padding-left: 7px; }
    .body_module .pagination {
      text-align: center;
      padding-bottom: 30px; }
    .body_module .pagination .btn_std {
      display: inline-block;
      width: 181px; }
    .body_module .pagination .btn_std.prev {
      margin-right: 20px; }
    .body_module .pagination .btn_std.next {
      margin-left: 20px; }
    .body_module .pagination .btn_std.prev a {
      background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
      background-position: left 10px bottom 17px; }
    .body_module .pagination .btn_std a {
      background: none; }
    .body_module .pagination .btn_std.next a {
      background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
      background-position: right 10px bottom 17px; }
    .body_module .layout_L .pagenation {
      text-align: center; }
      .body_module .layout_L .pagenation .prev {
        background: none;
        color: #26323c; }
      .body_module .layout_L .pagenation .next {
        background: none; }
      .body_module .layout_L .pagenation .nav-links {
        display: inline-block; }
      .body_module .layout_L .pagenation .current {
        float: left;
        margin-right: 6px;
        padding: 5px 14px;
        color: #fff;
        background: #26323c;
        display: block; }
      .body_module .layout_L .pagenation a {
        float: left;
        background: #bfbfbf;
        margin-right: 6px;
        padding: 5px 14px;
        color: #26323c;
        display: block; }
      .body_module .layout_L .pagenation a:hover {
        color: #fff;
        background: #26323c; }
    .body_module .module_fotter .footer_inner .inner {
      margin-top: 0; }
    .body_module .page_basic h1 {
      font-size: 35px;
      margin-bottom: 30px; }
    .body_module .page_basic h2 {
      font-size: 30px;
      margin-bottom: 30px; }
    .body_module .page_basic h3 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold; }
    .body_module .page_basic h4 {
      font-weight: bold;
      margin-top: 20px; }
    .body_module .page_basic .right_cnt .inner {
      width: 465px;
      margin: 0; }
    .body_module .page_basic .box {
      padding-bottom: 30px; }
      .body_module .page_basic .box li {
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px; }
      .body_module .page_basic .box ul.dot li {
        list-style-type: disc; }
      .body_module .page_basic .box ul.kakko li {
        list-style: none;
        text-indent: -20px;
        margin-left: 20px; }
    .body_module .page_basic .basic_img {
      width: 386px; }
      .body_module .page_basic .basic_img img {
        margin-bottom: 20px; }
    .body_module table {
      border-bottom: solid 1px #aaaaaa; }
      .body_module table th {
        width: 120px;
        background: #e6f4ff;
        font-size: 18px;
        text-align: center;
        vertical-align: middle; }
      .body_module table td {
        vertical-align: middle; }
      .body_module table th.bunya {
        padding: 33px 47px;
        vertical-align: middle;
        border-bottom: solid 2px #aaaaaa;
        border-right: solid 1px #aaaaaa; }
      .body_module table th.name {
        padding: 15px;
        width: 563px;
        border-bottom: solid 1px #aaaaaa; }
      .body_module table th.kikan {
        padding: 33px 27px;
        border-bottom: solid 2px #aaaaaa;
        border-left: solid 1px #aaaaaa; }
      .body_module table .bottom_title td {
        background: #e6f4ff;
        text-align: center;
        border-bottom: solid 2px #0071c5;
        width: 296px;
        padding: 10px 0; }
      .body_module table .bottom_title .minato {
        border-right: solid 1px #aaaaaa; }
      .body_module table .all {
        border-bottom: dotted 1px #aaaaaa; }
        .body_module table .all th {
          border-right: solid 1px #aaaaaa; }
        .body_module table .all td {
          padding: 10px 17px; }
        .body_module table .all .hospital_name {
          border-right: solid 1px #aaaaaa; }
        .body_module table .all .links {
          border-right: solid 1px #aaaaaa; }
        .body_module table .all a {
          color: #000; }
        .body_module table .all a:hover {
          color: #0071c5; }
      .body_module table .style01 .field {
        background: #f9f1e5; }
      .body_module table .style02 .field {
        background: #f0e4d3; }
      .body_module table .style02 td {
        background: #ededed; }
      .body_module table .choice {
        width: 50px;
        border-right: solid 1px #aaaaaa; } }

@media (max-width: 767px) {
  /*-------------------------------------------

SP 共通部分

-------------------------------------------*/
  .sp-on {
    display: block; }
  .sp-on img {
    display: block; }
  img {
    width: 100%; }
  body {
    font-family: 'Noto Sans Japanese', sans-serif, "Meiryo", "メイリオ", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
    font-size: 14px;
    line-height: 1.5; }
  .header {
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    padding-top: 10px; }
    .header .inner {
      width: 100%; }
    .header_logo {
      padding: 0 5%;
      margin: 0 auto;
      margin-bottom: 10px; }
    .header .gNav {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      font-size: 10px;
      background-color: #7f7f7e;
      opacity: 0.8; }
      .header .gNav .pc-on {
        display: none !important; }
      .header .gNav li {
        height: 60px;
        width: calc((100% - 5px ) / 6);
        text-align: center;
        background-color: #7f7f7e;
        border-right: 1px solid #fff;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center; }
        .header .gNav li a {
          color: #fff; }
        .header .gNav li:last-child {
          border: none; }
    .header .sp_menuBtn {
      width: 94%;
      padding: 10px 3%;
      background-color: #26323c;
      color: #fff; }
      .header .sp_menuBtn_L {
        float: left; }
      .header .sp_menuBtn_R {
        float: right;
        width: 25px; }
    .header .megaMenu {
      display: none;
      width: 94%;
      padding: 3%;
      background-color: #3f4d58;
      height: 480px;
      overflow: auto; }
      .header .megaMenu li {
        position: relative;
        border-bottom: 1px solid #696a66; }
        .header .megaMenu li a {
          color: #fff;
          padding: 3% 2%;
          display: block; }
      .header .megaMenu li:after {
        position: absolute;
        content: "＞";
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff; }
      .header .megaMenu .close {
        width: 10%;
        margin: 0 auto;
        margin-top: 15px; }
        .header .megaMenu .close .sp_menuBtn_R {
          float: none; }
  .footer {
    padding: 30px 25px;
    background-color: #26323c; }
    .footer .footer_inner {
      position: relative; }
    .footer a {
      color: #fff;
      padding: 9px 0 14px;
      display: block; }
    .footer .to_top {
      position: absolute;
      width: 30px;
      top: -54px;
      right: 0; }
    .footer .footer_menu--type2 {
      display: none; }
    .footer_menu {
      margin-bottom: 40px;
      font-size: 10px; }
      .footer_menu .menu--icon {
        background-image: url(../img/icon01.png);
        background-repeat: no-repeat;
        background-position: 98% 0%; }
    .footer_menu li {
      border-bottom: 1px solid #696a66; }
    .footer .footer_menu--sns {
      text-align: center; }
      .footer .footer_menu--sns li {
        display: inline-block;
        width: 50px;
        margin: 0 10px; }
    .footer .cr_text {
      color: #fff;
      text-align: center;
      font-size: 10px;
      margin-top: 20px; }
  #content {
    margin-bottom: 70px; }
  section {
    margin-top: 15%; }
    section .inner {
      padding: 0 15px; }
  /*
text_link
-------------------------------------------*/
  .text_link a {
    color: #000; }
  /*
text_icon_style
-------------------------------------------*/
  .cercle_icon_blue a {
    background: url(../img/common/cercle_icon_blue.png) no-repeat right;
    height: 22px;
    padding-right: 30px; }
  .cercle_icon_kuro a {
    color: #000;
    background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
    height: 28px;
    padding-right: 30px; }
  .site_icon a {
    background: url(../img/common/icon02.png) no-repeat right;
    height: 18px;
    padding-right: 23px; }
  /*
line_decoration
-------------------------------------------*/
  .line_long_01 {
    width: 80px;
    border-bottom: solid 1px #000;
    float: left;
    margin-top: 15px; }
  .line_long_02 {
    width: 10px;
    border-bottom: solid 1px #000;
    float: left;
    margin-top: 15px; }
  .line_long_03 {
    width: 10px;
    border-bottom: solid 1px #000;
    float: left;
    margin-top: 15px; }
  .line_short_01 {
    width: 10px;
    border-bottom: solid 1px #000;
    float: left;
    margin-top: 15px; }
  .line_short_02 {
    width: 10px;
    border-bottom: solid 1px #000;
    float: left;
    margin-top: 15px; }
  /*
btn_style
-------------------------------------------*/
  .btn_std {
    background-color: #fff;
    border: solid 1px #26323c;
    border-radius: 6px;
    width: 230px;
    margin-top: 10px;
    margin: 0 auto;
    margin-top: 15px; }
    .btn_std a {
      color: #000;
      padding: 14px 10px;
      display: block;
      background: url(../img/common/cercle_icon_kuro.png) no-repeat;
      height: 22px;
      text-decoration: none;
      background-position: right 7px bottom 14px; }
  .line_more {
    position: relative;
    float: right;
    background-color: #fff;
    z-index: 100;
    bottom: -10px;
    padding-left: 12px; }
  .line_more_up {
    padding-top: 20px; }
  .line_more_first {
    bottom: 30px; }
  .line_more_last {
    bottom: 12px; }
  .page_blog .line_more {
    position: relative;
    float: right;
    background-color: #fff;
    z-index: 100;
    bottom: 15px;
    padding-left: 12px; }
  /*-------------------------------------------

sp _index css

-------------------------------------------*/
  .contact_btn {
    position: absolute;
    right: 0;
    top: 112px;
    z-index: 100;
    float: right;
    background: #fff;
    opacity: 0.8; }
    .contact_btn a {
      color: #0071c5;
      background: url(../img/common/cercle_icon_blue_sp.png) no-repeat top 12px right 20px;
      height: 27px;
      padding: 10px 46px 15px 20px;
      display: block; }
  .main_visual {
    position: relative; }
    .main_visual .slider {
      width: 100%;
      height: 520px; }
      .main_visual .slider #theTarget .slide01 {
        background: url(../img/top/top_slide01_sp.png) no-repeat;
        background-size: cover; }
      .main_visual .slider #theTarget .slide02 {
        background: url(../img/top/top_slide02_sp.png) no-repeat;
        background-size: cover; }
      .main_visual .slider #theTarget .slide03 {
        background: url(../img/top/top_slide03_sp.png) no-repeat;
        background-size: cover; }
    .main_visual .header_top {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100; }
      .main_visual .header_top .header_inner {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        margin-top: 20px; }
        .main_visual .header_top .header_inner .logo {
          float: left; }
    .main_visual .key_text {
      position: absolute;
      top: 300px;
      width: 100%;
      z-index: 100; }
      .main_visual .key_text .wrap {
        width: 100%;
        margin: 0 auto; }
  #top_content .g_navi {
    position: relative;
    z-index: 10000;
    width: 100%;
    margin: 0 auto;
    height: 65px;
    background: #fff;
    padding-top: 24px; }
    #top_content .g_navi li {
      float: left; }
    #top_content .g_navi li:last-child {
      padding-right: 0; }
    #top_content .g_navi a {
      width: 20px;
      color: #333333;
      text-decoration: none;
      padding-bottom: 10px;
      background: url(../img/top/top_05_pc.png) no-repeat bottom center; }
    #top_content .g_navi a:hover {
      color: #0071c5;
      background: url(../img/top/top_04_pc.png) no-repeat bottom center; }
  #top_content .fixed {
    position: fixed !important;
    top: 0;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); }
  #top_content table th {
    padding: 10px 50px 0 0; }
  #top_content table td {
    padding: 10px 0 0; }
    #top_content table td a {
      background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
      width: 20%;
      height: 22px;
      padding-right: 30px; }
  #top_content table a {
    color: #000; }
  .inner section .content_title h2 {
    font-size: 21px;
    letter-spacing: 3px; }
  .subtitle {
    position: relative;
    background: #fff;
    border: solid 1px #cccccc;
    padding: 30px 69px; }
    .subtitle .first_title {
      font-size: 21px;
      text-align: center; }
    .subtitle .second_title {
      text-align: center; }
  .sub_text_box {
    width: 100%;
    margin-top: 20px;
    border-bottom: solid 1px #000; }
  .first_cnt .inner {
    padding: 20px; }
  .first_cnt .more_text {
    margin: 20px 0;
    border-bottom: solid 1px #000;
    padding-bottom: 35px; }
  .training_cnt .subtitle_bg {
    content: url(../img/top/top_01_sp.png);
    width: 100%; }
  .training_cnt .subtitle {
    margin-top: -20px; }
  .message_cnt .subtitle_bg {
    content: url(../img/top/top_02_sp.png);
    width: 100%; }
  .message_cnt .subtitle {
    margin-top: -20px;
    padding: 30px 0; }
  .recruitment_cnt {
    position: relative; }
    .recruitment_cnt .subtitle_bg {
      content: url(../img/top/top_03_sp.png);
      width: 100%; }
    .recruitment_cnt .subtitle {
      width: 100%;
      position: absolute;
      bottom: 30px;
      background: none;
      border: none;
      padding: 0; }
      .recruitment_cnt .subtitle .first_title {
        line-height: 50px; }
  .map_cnt {
    width: 100%;
    background-color: #eaece6; }
    .map_cnt .inner {
      padding-top: 10px; }
    .map_cnt .trafic_links {
      margin: 40px 0; }
  .news_cnt .table_ctrl {
    border-bottom: solid 1px #000;
    padding-bottom: 25px; }
  .news_cnt .news_title {
    font-size: 18px;
    display: block; }
  .news_cnt .table_ctrl {
    padding: 0 0 30px; }
  .trafic_title {
    font-size: 18px; }
  /*-------------------------------------------

SP 下層ページ 共通部分

-------------------------------------------*/
  .breadcrumb {
    padding: 10px; }
    .breadcrumb a {
      color: #000; }
    .breadcrumb a:hover {
      color: #0071c5; }
  .page_main_visual {
    position: relative; }
    .page_main_visual .page_title_box {
      position: relative;
      top: 80px;
      width: 90%;
      margin: 0 auto;
      border: solid 1px #cccccc; }
    .page_main_visual .item_top {
      background: #fff;
      padding: 10px 10px; }
    .page_main_visual h1 {
      font-size: 24px;
      text-align: center; }
    .page_main_visual .english_title {
      text-align: center; }
    .page_main_visual .item_bottm {
      background: #26323c;
      color: #fff;
      padding: 10px;
      min-height: 130px; }
  .page_title_only {
    font-size: 25px;
    margin-bottom: 10px; }
  input, textarea {
    border: solid 1px #cccccc; }
  .layout_L {
    width: 100%;
    margin: 0 auto; }
  .layout_R {
    width: 100%;
    margin: 0 auto; }
  /*-------------------------------------------

グロナビアクティブ

-------------------------------------------*/
  #wrap_program .gNav li:nth-child(2) {
    background: #0071c5; }
  #wrap_traning .gNav li:nth-child(3) {
    background: #0071c5; }
  #wrap_message .gNav li:nth-child(4) {
    background: #0071c5; }
  #wrap_application .gNav li:nth-child(5) {
    background: #0071c5; }
  #wrap_contact .gNav li:nth-child(6) {
    background: #0071c5; }
  #wrap_access .gNav li:nth-child(7) {
    background: #0071c5; }
  /*-------------------------------------------

h1ボーダー

-------------------------------------------*/
  .change-border-color {
    position: relative; }
  .change-border-color::before {
    border-bottom: solid 2px #0071c5;
    width: 30%;
    position: absolute;
    z-index: 1;
    bottom: -6px;
    left: 0;
    content: ""; }
  .change-border-color::after {
    border-bottom: solid 2px #cccccc;
    width: 100%;
    position: absolute;
    bottom: -6px;
    left: 0;
    content: ""; }
  /*-------------------------------------------

研修プログラム

-------------------------------------------*/
  #wrap_program {
    /*---ご挨拶---*/
    /*---プログラムの目標---*/
    /*---学習会・勉強会---*/
    /*---プログラムの特徴---*/
    /*---プログラムの概要---*/
    /*--- 研修スケジュール ---*/
    /*--- 研修体制 ---*/
    /*--- 研修施設 ---*/
    /*--- 研修医データ ---*/
    /*--- 研修修了後の進路 ---*/ }
    #wrap_program h2 {
      font-size: 25px;
      margin-bottom: 10px; }
    #wrap_program table th {
      vertical-align: top; }
    #wrap_program table td {
      padding: 0 0 20px 5px; }
    #wrap_program .page_greeting h1 {
      font-size: 25px;
      margin-bottom: 30px; }
    #wrap_program .page_greeting .left_cnt img {
      width: 100%; }
    #wrap_program .page_greeting .right_cnt .inner {
      margin: 0; }
    #wrap_program .page_greeting .right_cnt .name {
      font-weight: bold;
      margin-top: 25px; }
      #wrap_program .page_greeting .right_cnt .name span {
        font-size: 30px; }
    #wrap_program .page_greeting .right_cnt .title {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
      line-height: 30px; }
    #wrap_program .page_greeting .right_cnt .text_deco {
      margin-top: 10px;
      border-bottom: solid 1px #000;
      padding-bottom: 50px; }
    #wrap_program .page_greeting .box {
      margin-bottom: 50px; }
    #wrap_program .page_target .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_meeting .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_feature .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_feature .inner .box ol {
      list-style: decimal;
      margin: 0px 0px 0px 20px; }
    #wrap_program .page_feature .inner .box ol li {
      line-height: 2;
      margin-bottom: 40px; }
    #wrap_program .page_feature .inner .box ol.sm-space li {
      line-height: 2;
      margin-bottom: 0px; }
    #wrap_program .page_feature .inner .img-holder {
      margin-top: 40px;
      margin-bottom: 84px; }
    #wrap_program .page_outline .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_outline dl {
      display: block;
      width: 100%;
      border: 1px solid #ebebeb; }
      #wrap_program .page_outline dl dt, #wrap_program .page_outline dl dd {
        display: block;
        padding: 22px;
        border-top: 1px solid #ebebeb; }
      #wrap_program .page_outline dl dt {
        text-align: center; }
    #wrap_program .page_outline dl:last-child dd {
      border-bottom: 1px solid #ebebeb; }
    #wrap_program .page_outline span.divider {
      display: block;
      border-top: 1px solid #ebebeb; }
    #wrap_program .page_outline span.divider:after {
      content: url("../img/program/icon-arrow-down.png");
      display: block;
      position: relative;
      width: 52px;
      margin: 30px auto; }
    #wrap_program .page_outline ul {
      margin: 0px 0px 0px 20px; }
      #wrap_program .page_outline ul li {
        list-style: disc; }
    #wrap_program .page_training_schedule .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_system .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_system section .img-holder {
      margin-top: 44px; }
    #wrap_program .page_system section img {
      margin-bottom: 40px; }
    #wrap_program .page_facilities .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_facilities section p {
      margin-top: 28px; }
    #wrap_program .page_facilities table {
      border-bottom: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table th {
        width: 10%;
        background: #e6f4ff;
        font-size: 18px;
        text-align: center;
        vertical-align: middle; }
      #wrap_program .page_facilities table td {
        vertical-align: middle; }
      #wrap_program .page_facilities table th.bunya {
        padding: 2%;
        vertical-align: middle;
        border-bottom: solid 2px #aaaaaa;
        border-right: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table th.name {
        padding: 2%;
        width: 62%;
        border-bottom: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table th.kikan {
        padding: 2%;
        border-bottom: solid 2px #aaaaaa;
        border-left: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table .bottom_title td {
        background: #e6f4ff;
        text-align: center;
        border-bottom: solid 2px #0071c5;
        padding: 2%; }
      #wrap_program .page_facilities table .bottom_title .minato {
        padding: 2%;
        border-right: solid 1px #aaaaaa; }
      #wrap_program .page_facilities table .all {
        border-bottom: dotted 1px #aaaaaa; }
        #wrap_program .page_facilities table .all th {
          border-right: solid 1px #aaaaaa; }
        #wrap_program .page_facilities table .all td {
          padding: 2%; }
        #wrap_program .page_facilities table .all .hospital_name {
          border-right: solid 1px #aaaaaa; }
        #wrap_program .page_facilities table .all a {
          color: #000; }
        #wrap_program .page_facilities table .all a:hover {
          color: #0071c5; }
      #wrap_program .page_facilities table .style01 .field {
        padding: 2%;
        background: #f9f1e5; }
      #wrap_program .page_facilities table .style02 .field {
        padding: 2%;
        background: #f0e4d3; }
      #wrap_program .page_facilities table .style02 td {
        background: #ededed; }
      #wrap_program .page_facilities table .choice {
        width: 50px;
        border-right: solid 1px #fff !important; }
    #wrap_program .page_data .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_data section h3 {
      margin-bottom: 40px; }
    #wrap_program .page_course .page_main_visual {
      background: url(../img/program/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_program .page_course section h3 {
      margin-bottom: 40px; }
  /*-------------------------------------------

研修科目

-------------------------------------------*/
  #wrap_traning {
    /*---index---*/
    /*---基本科目・必修科目---*/
    /* page rotate */ }
    #wrap_traning h2 {
      font-size: 25px;
      margin-bottom: 30px; }
    #wrap_traning table th {
      vertical-align: top; }
    #wrap_traning table td {
      padding: 0 0 20px 5px; }
    #wrap_traning .box {
      padding-bottom: 30px; }
    #wrap_traning .links span {
      width: 46%;
      margin: 0 4px;
      border-radius: 5px;
      border: solid 1px #cccccc;
      float: left;
      margin-bottom: 20px;
      text-align: center; }
      #wrap_traning .links span a {
        padding: 15px 23px;
        display: block;
        color: #000; }
      #wrap_traning .links span a:after {
        content: url(../img/common/bottom_blue_icon.png);
        padding-left: 7px; }
    #wrap_traning .right_cnt {
      float: none;
      clear: both; }
    #wrap_traning .page_index .page_main_visual {
      background: url(../img/traning/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    #wrap_traning .page_basic h1 {
      font-size: 25px;
      margin-bottom: 30px; }
    #wrap_traning .page_basic h2 {
      font-size: 30px;
      margin-bottom: 30px; }
    #wrap_traning .page_basic h3 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold; }
    #wrap_traning .page_basic h4 {
      font-weight: bold;
      margin-top: 10px; }
    #wrap_traning .page_basic .right_cnt .inner {
      margin: 0; }
    #wrap_traning .page_basic .box li {
      list-style: decimal;
      list-style-position: outside;
      margin-left: 20px; }
    #wrap_traning .page_basic .box ul.dot li {
      list-style-type: disc; }
    #wrap_traning .page_basic .box ul.kakko li {
      list-style: none;
      text-indent: -15px;
      margin-left: 15px; }
    #wrap_traning .page_basic .basic_img {
      margin-bottom: 20px; }
    #wrap_traning .traning_top {
      width: 90%;
      margin: 0 auto;
      clear: both;
      border-bottom: solid 1px #000; }
      #wrap_traning .traning_top .line_more_first {
        bottom: -10px; }
    #wrap_traning .page_rotate h1 {
      font-size: 25px;
      margin-bottom: 47px; }
    #wrap_traning .page_rotate h2 {
      font-size: 20px; }
    #wrap_traning .page_rotate section img {
      margin: 20px 0px; }
  /*-------------------------------------------

募集と採用

-------------------------------------------*/
  #wrap_application {
    /*---index---*/ }
    #wrap_application h1 {
      font-size: 25px;
      margin-bottom: 30px; }
    #wrap_application h2 {
      font-size: 25px;
      margin-bottom: 30px; }
    #wrap_application h3 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold; }
    #wrap_application h4 {
      font-weight: bold;
      margin-top: 10px; }
    #wrap_application .box {
      padding-bottom: 30px; }
    #wrap_application .btn_std {
      width: 100%;
      margin-top: 0; }
    #wrap_application .bottom_btn .btn_std {
      width: 90%;
      margin-top: 30px; }
    #wrap_application .right_cnt {
      float: none;
      clear: both; }
    #wrap_application .page_index h2 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold; }
    #wrap_application .page_index h3 {
      font-size: 15px;
      font-weight: bold;
      margin-top: 10px; }
    #wrap_application .page_index .right_cnt .inner {
      margin: 0; }
    #wrap_application .page_index .box li {
      list-style: decimal;
      list-style-position: outside;
      margin-left: 20px; }
    #wrap_application .page_index .box ul.dot li {
      list-style-type: disc; }
    #wrap_application .page_index .box ul.kakko li {
      list-style: none;
      text-indent: -20px;
      margin-left: 20px; }
    #wrap_application .page_index .basic_img {
      margin-bottom: 20px; }
      #wrap_application .page_index .basic_img img {
        width: 100%; }
  /*-------------------------------------------

メッセージ

-------------------------------------------*/
  body#wrap_message .links span {
    width: 48%;
    margin: 0 1%;
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #cccccc;
    float: left;
    margin-bottom: 20px;
    text-align: center; }
    body#wrap_message .links span a {
      padding: 15px 23px;
      display: block;
      color: #000; }
    body#wrap_message .links span a:after {
      content: url(../img/common/bottom_blue_icon.png);
      padding-left: 7px; }
  body#wrap_message .page_message .page_main_visual {
    background: url(../img/message/key_img_sp.png) no-repeat;
    height: 280px;
    background-size: contain; }
  body#wrap_message .page_message .doctor_cnt {
    width: 100%;
    margin: auto; }
    body#wrap_message .page_message .doctor_cnt .doctor {
      float: left;
      width: 43%;
      margin-left: 4%;
      margin-right: 3%;
      margin-bottom: 60px; }
      body#wrap_message .page_message .doctor_cnt .doctor a {
        color: #000; }
    body#wrap_message .page_message .doctor_cnt .last {
      margin-right: 0; }
    body#wrap_message .page_message .doctor_cnt .doctor_img {
      width: auto;
      text-align: center; }
      body#wrap_message .page_message .doctor_cnt .doctor_img img {
        vertical-align: bottom;
        display: block;
        margin: 0 auto; }
    body#wrap_message .page_message .doctor_cnt .doctor_text_box {
      background: #fff;
      border: solid 1px #cccccc;
      padding: 10px; }
      body#wrap_message .page_message .doctor_cnt .doctor_text_box .text {
        min-height: 130px; }
      body#wrap_message .page_message .doctor_cnt .doctor_text_box .name {
        padding-top: 20px;
        text-align: center; }
  body#wrap_message .page_message .name_box {
    margin-bottom: 20px; }
  body#wrap_message .page_message .category_name {
    font-size: 20px;
    border-bottom: solid 1px #000; }
  body#wrap_message .page_message_single img {
    max-width: 100%;
    height: auto; }
  body#wrap_message .page_message_single .category {
    font-size: 15px;
    border-bottom: solid 1px #000; }
  body#wrap_message .page_message_single section {
    margin-bottom: 50px; }
    body#wrap_message .page_message_single section .left-box {
      float: left;
      width: 55%; }
      body#wrap_message .page_message_single section .left-box h1 {
        font-size: 30px; }
    body#wrap_message .page_message_single section .right-box {
      width: 40%;
      float: right; }
      body#wrap_message .page_message_single section .right-box img {
        max-width: 100%; }
  body#wrap_message .page_message_single h3 {
    font-size: 20px;
    margin-bottom: 30px; }
  body#wrap_message .page_message_single .pagination {
    text-align: center;
    border-bottom: solid 1px #000;
    padding-bottom: 15%; }
    body#wrap_message .page_message_single .pagination .btn_std {
      display: block;
      width: 181px; }
    body#wrap_message .page_message_single .pagination .btn_std a {
      background: transparent; }
    body#wrap_message .page_message_single .pagination .btn_std.prev a {
      background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
      background-position: left 10px bottom 17px; }
    body#wrap_message .page_message_single .pagination .btn_std.next a {
      background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
      background-position: right 10px bottom 17px; }
  /*-------------------------------------------

初期・後期振り分けページ

-------------------------------------------*/
  #wrap_furiwake header .inner {
    padding: 30px 0px; }
    #wrap_furiwake header .inner img.sp-on {
      margin: 0 auto;
      display: block;
      max-width: 80%; }
  #wrap_furiwake #content {
    padding-bottom: 0px;
    margin-bottom: 0px; }
    #wrap_furiwake #content section {
      margin-top: 0px; }
      #wrap_furiwake #content section .inner {
        position: relative;
        width: 100%;
        padding: 0px; }
        #wrap_furiwake #content section .inner * {
          box-sizing: border-box; }
        #wrap_furiwake #content section .inner .left_box {
          position: relative;
          float: none;
          width: 100%;
          text-align: center;
          background-size: cover;
          max-height: 700px;
          background-position: top center;
          background-image: url("../img/furiwake/furiwake_bg01_sp.png"); }
          #wrap_furiwake #content section .inner .left_box:before {
            display: block;
            content: " ";
            width: 100%;
            padding-top: 150%; }
          #wrap_furiwake #content section .inner .left_box > .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; }
        #wrap_furiwake #content section .inner .right_box {
          position: relative;
          float: none;
          width: 100%;
          text-align: center;
          background-size: cover;
          max-height: 700px;
          background-position: top center;
          background-image: url("../img/furiwake/furiwake_bg02_sp.png"); }
          #wrap_furiwake #content section .inner .right_box:before {
            display: block;
            content: " ";
            width: 100%;
            padding-top: 150%; }
          #wrap_furiwake #content section .inner .right_box > .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0; }
        #wrap_furiwake #content section .inner div a {
          font-size: 20px;
          color: #000000;
          text-decoration: none;
          padding: 35px 0;
          text-align: center;
          display: block;
          background-color: rgba(255, 255, 255, 0.66);
          border: 1px solid #ebebeb;
          border-right: 0px;
          width: 80%;
          left: 50%;
          position: absolute;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
        #wrap_furiwake #content section .inner div a:hover {
          color: #0071c5;
          background-color: #e6f4ff;
          opacity: 0.7; }
  #wrap_furiwake footer .footer_menu--type2 {
    display: block; }
  #wrap_furiwake footer .footer_L {
    float: none;
    text-align: center; }
  #wrap_furiwake footer .footer_R {
    display: block;
    float: none;
    text-align: center; }
    #wrap_furiwake footer .footer_R ul li a {
      width: 47%;
      display: block;
      float: left; }
    #wrap_furiwake footer .footer_R ul li:last-child a {
      float: right; }
  /*-------------------------------------------

お申し込み・お問い合わせ

-------------------------------------------*/
  .contactform {
    margin-top: 30px; }
    .contactform .min {
      font-size: 14px; }
    .contactform .form_cnt {
      border-bottom: solid 1px #000;
      padding: 30px 0; }
      .contactform .form_cnt .group {
        padding-bottom: 20px; }
      .contactform .form_cnt .category_neme {
        font-size: 20px;
        display: block;
        padding: 0 0 10px; }
      .contactform .form_cnt .category_neme_ctrl {
        padding: 20px 0 0; }
      .contactform .form_cnt .form-control {
        width: 90%;
        height: 30px;
        padding: 6px 12px; }
      .contactform .form_cnt .ctrl {
        float: left;
        margin-right: 20px; }
    .contactform .date {
      float: left;
      margin-right: 10px; }
    .contactform .date_text_ctrl {
      margin-top: -4px;
      float: left;
      margin-right: 10px; }
    .contactform .checkbox_ctrl {
      margin-right: 20px;
      float: left; }
    .contactform .textarea_ctrl {
      width: 100%;
      height: 70px; }
    .contactform .bottom_box {
      margin: 50px 0 0; }
    .contactform .agree_cnt {
      text-align: center;
      margin: 20px 0; }
    .contactform .btn_std {
      margin: 0 auto;
      text-align: center; }
      .contactform .btn_std a {
        background: none; }
    .contactform .reset_text {
      text-align: center;
      margin-top: 20px; }
      .contactform .reset_text a {
        color: #000;
        background: #e5e5e5;
        padding: 5px 20px; }
      .contactform .reset_text a:hover {
        text-decoration: underline; }
  .thanks_title {
    font-size: 25px;
    text-align: center; }
  .thanks_text {
    text-align: center;
    margin-bottom: 50px; }
  .error_message {
    color: #ff0004;
    display: none; }
  .error,
  #form_grade_error,
  .agree_error {
    color: #FF0004;
    font-size: 15px; }
  .agree_error {
    display: none; }
  .send_button {
    -webkit-appearance: none;
    width: 260px;
    margin: 0 auto;
    background-color: #fff;
    display: block;
    border-radius: 5px;
    line-height: 30px;
    cursor: pointer; }
  /*-------------------------------------------

お知らせ

-------------------------------------------*/
  .blog_cnt {
    padding-bottom: 30px;
    padding-top: 20px; }
    .blog_cnt .date {
      float: left;
      margin-right: 20px;
      font-size: 18px; }
    .blog_cnt .category {
      padding: 3px 30px;
      border-radius: 5px; }
    .blog_cnt .blog_title {
      font-size: 20px;
      margin: 20px 0; }
    .blog_cnt .blog_text {
      margin-top: 20px;
      border-bottom: solid 1px #000;
      padding-bottom: 30px; }
  .layout_R .category {
    border-top: solid 1px #000; }
    .layout_R .category li {
      border-bottom: dotted 1px #000;
      padding: 10px 0 10px 15px; }
      .layout_R .category li a {
        color: #000;
        display: block; }
    .layout_R .category li:hover {
      background: url(../img/common/migi_blue_icon.png) no-repeat left;
      position: relative; }
  .pagenation {
    width: 100%;
    margin: 50px auto;
    position: relative;
    overflow: hidden;
    text-align: center; }
    .pagenation .prev {
      background: none;
      color: #26323c;
      padding: 5px 0; }
    .pagenation .next {
      background: none;
      padding: 5px 0;
      color: #26323c; }
    .pagenation .nav-links {
      float: left;
      position: relative;
      left: 50%; }
    .pagenation .current {
      float: left;
      background: #bfbfbf;
      margin-right: 6px;
      position: relative;
      left: -50%;
      padding: 5px 14px;
      color: #26323c;
      display: block; }
    .pagenation a {
      float: left;
      color: #fff;
      background: #26323c;
      margin-right: 6px;
      position: relative;
      left: -50%;
      padding: 5px 14px;
      display: block; }
    .pagenation a:hover {
      color: #fff;
      background: #26323c; }
    .pagenation .clear {
      background: none; }
  .page_blog_single img {
    max-width: 100%;
    height: auto; }
  /*-------------------------------------------

個人情報の取扱について

-------------------------------------------*/
  #wrap_privacy h1 {
    font-size: 25px; }
  #wrap_privacy h2 {
    font-size: 20px;
    font-weight: bold; }
  #wrap_privacy .last_name {
    float: right;
    text-align: right;
    margin-top: 30px; }
  /*-------------------------------------------

エラー画面

-------------------------------------------*/
  #wrap_error .inner_ctrl {
    padding-top: 30px;
    text-align: center; }
  #wrap_error .not_found {
    font-size: 40px;
    color: #666666; }
  #wrap_error .sorry {
    font-size: 25px;
    margin-top: 20px; }
  #wrap_error .btn_std {
    margin-top: 30px; }
  body#wrap_blog {
    /* single */ }
    body#wrap_blog .page_blog_single section {
      margin-top: 50px; }
    body#wrap_blog .page_blog_single .blog_cnt img {
      margin: 0 auto;
      display: block;
      margin-bottom: 30px; }
    body#wrap_blog .page_blog_single .pagination {
      text-align: center;
      margin-bottom: 60px; }
      body#wrap_blog .page_blog_single .pagination .btn_std {
        display: block;
        width: 181px;
        margin-bottom: 12px;
        margin-top: 0px; }
      body#wrap_blog .page_blog_single .pagination .btn_std a {
        background: transparent; }
      body#wrap_blog .page_blog_single .pagination .btn_std.prev a {
        background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
        background-position: left 10px bottom 17px; }
      body#wrap_blog .page_blog_single .pagination .btn_std.next a {
        background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
        background-position: right 10px bottom 17px; }
    body#wrap_blog .page_blog_single .sns_box {
      margin: 34px 0px; }
      body#wrap_blog .page_blog_single .sns_box ul {
        margin: 0px; }
        body#wrap_blog .page_blog_single .sns_box ul li {
          display: inline-block;
          line-height: 1; }
  /*-------------------------------------------

アクセス

-------------------------------------------*/
  body#wrap_access section {
    margin-bottom: 60px; }
  body#wrap_access h1 {
    font-size: 25px;
    margin-bottom: 30px; }
  body#wrap_access h2 {
    font-size: 20px;
    margin-bottom: 23px; }
  body#wrap_access .page_access .map-box .left-box iframe {
    margin: 0 auto;
    display: block;
    margin-bottom: 60px; }
  body#wrap_access .page_access .map-box .right-box {
    width: 100%; }
    body#wrap_access .page_access .map-box .right-box .enlarge {
      border-radius: 5px;
      border: solid 1px #cccccc;
      display: block;
      padding: 16px 0px;
      padding-left: 55px;
      max-width: 254px;
      width: 100%;
      box-sizing: border-box;
      margin: 20px auto; }
      body#wrap_access .page_access .map-box .right-box .enlarge a {
        color: #000000; }
      body#wrap_access .page_access .map-box .right-box .enlarge a:before {
        content: url(../img/common/bottom_blue_icon.png);
        padding-right: 7px; }
  /*-------------------------------------------

モジュール

-------------------------------------------*/
  .body_module {
    /*-------------------------------------------
title
-------------------------------------------*/
    /*-------------------------------------------
list
-------------------------------------------*/
    /*-------------------------------------------
layout
-------------------------------------------*/
    /*-------------------------------------------
blog
-------------------------------------------*/
    /*-------------------------------------------
message
-------------------------------------------*/
    /*-------------------------------------------
お申し込み・お問い合わせ
-------------------------------------------*/
    /*-------------------------------------------
btn
-------------------------------------------*/
    /*-------------------------------------------
pagination
-------------------------------------------*/
    /*-------------------------------------------
table
-------------------------------------------*/ }
    .body_module .page_main_visual {
      background: url(../img/traning/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain; }
    .body_module .menu_module {
      background-color: #ededed;
      padding: 16px 0; }
    .body_module .menu_module .inner {
      margin-top: 0; }
    .body_module .menu_module ul {
      text-align: center; }
    .body_module .module_title {
      font-size: 20px;
      padding: 10px;
      background: #f6b37f;
      color: #000;
      margin-bottom: 20px;
      margin-top: 50px; }
    .body_module .menu_module ul li {
      display: inline-block;
      border-right: 1px solid #000;
      padding: 0 37px; }
    .body_module .menu_module a {
      color: #000; }
    .body_module .menu_module a:hover {
      color: #0071c5; }
    .body_module .menu_module ul li:last-child {
      border: none; }
    .body_module .module_box_title {
      font-size: 20px;
      color: #ff0000;
      border-bottom: solid 1px #ff0000;
      padding-bottom: 10px;
      margin-top: 20px;
      margin-bottom: 20px; }
    .body_module h1 {
      font-size: 24px; }
    .body_module h2 {
      font-size: 20px; }
    .body_module .center_box {
      text-align: center; }
    .body_module .content_title h2 {
      font-size: 21px;
      letter-spacing: 3px; }
    .body_module .title_large {
      font-size: 25px;
      margin-bottom: 10px; }
    .body_module .title_midium {
      font-size: 20px;
      margin-bottom: 10px; }
    .body_module .title_midium_style02 {
      margin-bottom: 0; }
    .body_module .sub_title {
      margin-bottom: 20px; }
    .body_module .text_center {
      text-align: center; }
    .body_module .text_right {
      text-align: right; }
    .body_module .dot li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 20px; }
    .body_module .nomal li {
      list-style: decimal;
      list-style-position: outside;
      margin-left: 20px; }
    .body_module .kakko li {
      list-style: none;
      text-indent: -20px;
      margin-left: 20px; }
    .body_module .layout_R .category {
      border-top: solid 1px #000; }
    .body_module .layout_R .category li {
      border-bottom: dotted 1px #000;
      padding: 10px 0 10px 15px; }
    .body_module .layout_R .category li:hover {
      background: url(../img/common/migi_blue_icon.png) no-repeat left;
      position: relative; }
    .body_module .layout_R .category li a {
      color: #000;
      display: block; }
    .body_module .blog_cnt {
      padding-top: 40px; }
    .body_module .blog_cnt .date {
      float: left;
      margin-right: 20px;
      font-size: 18px; }
    .body_module .blog_cnt .category {
      padding: 3px 30px;
      border-radius: 5px; }
    .body_module .blog_cnt .blog_title {
      font-size: 25px !important;
      margin: 20px 0 !important; }
    .body_module .line_more {
      position: relative;
      float: right;
      background-color: #fff;
      z-index: 100;
      bottom: 15px;
      padding-left: 12px; }
    .body_module .line_more a {
      color: #000; }
    .body_module .blog_cnt .blog_text {
      margin-top: 20px;
      border-bottom: solid 1px #000;
      padding-bottom: 40px; }
    .body_module .cercle_icon_kuro a {
      background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
      height: 22px;
      padding-right: 30px; }
    .body_module .name_box {
      margin-bottom: 50px; }
    .body_module .category_name {
      font-size: 25px;
      border-bottom: solid 1px #000; }
    .body_module .doctor_cnt {
      width: 100%;
      margin: auto; }
      .body_module .doctor_cnt .doctor {
        float: left;
        width: 43%;
        margin-left: 4%;
        margin-right: 3%;
        margin-bottom: 60px; }
        .body_module .doctor_cnt .doctor a {
          color: #000; }
      .body_module .doctor_cnt .last {
        margin-right: 0; }
      .body_module .doctor_cnt .doctor_img {
        width: auto;
        text-align: center; }
        .body_module .doctor_cnt .doctor_img img {
          vertical-align: bottom;
          display: block;
          margin: 0 auto; }
      .body_module .doctor_cnt .doctor_text_box {
        background: #fff;
        border: solid 1px #cccccc;
        padding: 10px; }
        .body_module .doctor_cnt .doctor_text_box .text {
          min-height: 130px; }
        .body_module .doctor_cnt .doctor_text_box .name {
          padding-top: 20px;
          text-align: center; }
    .body_module .thanks_cnt .inner {
      min-height: 500px; }
    .body_module .thanks_title {
      font-size: 35px;
      text-align: center; }
    .body_module .thanks_text {
      text-align: center;
      margin-bottom: 70px; }
    .body_module .error_message {
      color: #ff0004;
      display: none; }
    .body_module .error,
    .body_module #form_grade_error,
    .body_module .agree_error {
      color: #FF0004;
      font-size: 15px; }
    .body_module .agree_error {
      display: none; }
    .body_module .send_button {
      -webkit-appearance: none;
      width: 260px;
      margin: 0 auto;
      background-color: #fff;
      display: block;
      border-radius: 5px;
      line-height: 30px;
      cursor: pointer; }
    .body_module .send_button:hover {
      -webkit-appearance: none;
      width: 260px;
      color: #0071c5;
      margin: 0 auto;
      display: block;
      border-radius: 5px;
      line-height: 30px;
      cursor: pointer;
      background: #e6f4ff; }
    .body_module .agree_error {
      color: #FF0004;
      font-size: 15px; }
    .body_module .contactform .agree_cnt {
      text-align: center;
      margin: 0 0 20px; }
    .body_module .defo_link a {
      color: #000;
      text-decoration: underline; }
    .body_module .links span {
      width: 46%;
      margin: 0 4px;
      border-radius: 5px;
      border: solid 1px #cccccc;
      float: left;
      margin-bottom: 20px;
      text-align: center; }
      .body_module .links span a {
        padding: 15px 23px;
        display: block;
        color: #000; }
      .body_module .links span a:after {
        content: url(../img/common/bottom_blue_icon.png);
        padding-left: 7px; }
    .body_module .pagination {
      text-align: center;
      padding-bottom: 30px; }
    .body_module .pagination .btn_std {
      display: inline-block;
      width: 181px; }
    .body_module .pagination .btn_std.prev a {
      background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
      background-position: left 10px bottom 17px; }
    .body_module .pagination .btn_std a {
      background: none; }
    .body_module .pagination .btn_std.next a {
      background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
      background-position: right 10px bottom 17px; }
    .body_module .layout_L .pagenation {
      text-align: center; }
      .body_module .layout_L .pagenation .prev {
        background: none;
        color: #26323c; }
      .body_module .layout_L .pagenation .next {
        background: none; }
      .body_module .layout_L .pagenation .nav-links {
        display: inline-block; }
      .body_module .layout_L .pagenation .current {
        float: left;
        margin-right: 6px;
        padding: 5px 14px;
        color: #fff;
        background: #26323c;
        display: block; }
      .body_module .layout_L .pagenation a {
        float: left;
        background: #bfbfbf;
        margin-right: 6px;
        padding: 5px 14px;
        color: #26323c;
        display: block; }
      .body_module .layout_L .pagenation a:hover {
        color: #fff;
        background: #26323c; }
    .body_module .module_fotter .footer_inner .inner {
      margin-top: 0; }
    .body_module .page_basic h1 {
      font-size: 35px;
      margin-bottom: 30px; }
    .body_module .page_basic h2 {
      font-size: 30px;
      margin-bottom: 30px; }
    .body_module .page_basic h3 {
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold; }
    .body_module .page_basic h4 {
      font-weight: bold;
      margin-top: 20px; }
    .body_module .page_basic .right_cnt .inner {
      width: 465px;
      margin: 0; }
    .body_module .page_basic .box {
      padding-bottom: 30px; }
      .body_module .page_basic .box li {
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px; }
      .body_module .page_basic .box ul.dot li {
        list-style-type: disc; }
      .body_module .page_basic .box ul.kakko li {
        list-style: none;
        text-indent: -20px;
        margin-left: 20px; }
    .body_module .page_basic .basic_img {
      width: 386px; }
      .body_module .page_basic .basic_img img {
        margin-bottom: 20px; }
    .body_module table {
      border-bottom: solid 1px #aaaaaa; }
      .body_module table th {
        width: 10%;
        background: #e6f4ff;
        font-size: 18px;
        text-align: center;
        vertical-align: middle; }
      .body_module table td {
        vertical-align: middle; }
      .body_module table th.bunya {
        padding: 2%;
        vertical-align: middle;
        border-bottom: solid 2px #aaaaaa;
        border-right: solid 1px #aaaaaa; }
      .body_module table th.name {
        padding: 2%;
        width: 62%;
        border-bottom: solid 1px #aaaaaa; }
      .body_module table th.kikan {
        padding: 2%;
        border-bottom: solid 2px #aaaaaa;
        border-left: solid 1px #aaaaaa; }
      .body_module table .bottom_title td {
        background: #e6f4ff;
        text-align: center;
        border-bottom: solid 2px #0071c5;
        padding: 2%; }
      .body_module table .bottom_title .minato {
        padding: 2%;
        border-right: solid 1px #aaaaaa; }
      .body_module table .all {
        border-bottom: dotted 1px #aaaaaa; }
        .body_module table .all th {
          border-right: solid 1px #aaaaaa; }
        .body_module table .all td {
          padding: 2%; }
        .body_module table .all .hospital_name {
          border-right: solid 1px #aaaaaa; }
        .body_module table .all a {
          color: #000; }
        .body_module table .all a:hover {
          color: #0071c5; }
      .body_module table .style01 .field {
        padding: 2%;
        background: #f9f1e5; }
      .body_module table .style02 .field {
        padding: 2%;
        background: #f0e4d3; }
      .body_module table .style02 td {
        background: #ededed; }
      .body_module table .choice {
        width: 50px;
        border-right: solid 1px #fff !important; } }

.float_l {
  float: left; }

.float_r {
  float: right; }

.mt5 {
  margin-top: 5px; }

.mt10 {
  margin-top: 10px; }

.mt20 {
  margin-top: 20px; }

.mt30 {
  margin-top: 30px; }

.mt40 {
  margin-top: 40px; }

.mt50 {
  margin-top: 50px; }

.mt60 {
  margin-top: 60px; }

.mb5 {
  margin-bottom: 5px; }

.mb10 {
  margin-bottom: 10px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

.mb40 {
  margin-bottom: 40px; }

.mb50 {
  margin-bottom: 50px; }

.mb60 {
  margin-bottom: 60px; }

.pr10 {
  padding-right: 10px; }

.pb50 {
  padding-bottom: 50px; }

.pt50 {
  padding-top: 50px; }

/*-------------------------------------------

テキスト

-------------------------------------------*/
.text_blue {
  color: #0071c5; }

.text_red {
  color: #FF0004;
  font-size: 14px; }

.text_blue a {
  color: #0071c5; }

.text_kuro {
  color: #332e2c; }

.text_kuro a {
  color: #332e2c; }

.font_b {
  font-weight: bold; }

/*-------------------------------------------

カテゴリーカラー

-------------------------------------------*/
.kikaku {
  background: #fffcd8; }

/*-------------------------------------------

コンテンツレイアウト

-------------------------------------------*/
.left_cnt {
  float: left; }

.right_cnt {
  float: right; }

.test {
  margin: 0; }
