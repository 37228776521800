/*-------------------------------------------

研修プログラム

-------------------------------------------*/
#wrap_program{
  & h2{
      font-size: 25px;
      margin-bottom: 10px;
    }
    & table{
      & th{
        vertical-align: top;
      }
      & td{
        padding: 0 0 20px 5px;
      }
    }
  
 /*---ご挨拶---*/
  .page_greeting{
    & h1{
      font-size: 25px;
      margin-bottom: 30px;
    }
    & .left_cnt{
      & img{
        width: 100%;
      }
    }
    & .right_cnt{
      & .inner{
        margin: 0;
      }
      & .name{
        font-weight: bold;
        margin-top: 25px;
        & span{
          font-size: 30px;
        }
      }
      & .title{
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;
        line-height: 30px;
      }
      & .text_deco{
        margin-top: 10px;
        border-bottom: solid 1px #000;
        padding-bottom: 50px;
      }
    }
    & .box{
      margin-bottom: 50px;
    }
  }
  
  /*---プログラムの目標---*/
  .page_target{
    & .page_main_visual{
      background: url(../img/program/key_img_sp.png)no-repeat;
      height: 280px;
      background-size: contain;
    }
  }
  
  /*---学習会・勉強会---*/
  .page_meeting{
    & .page_main_visual{
      background: url(../img/program/key_img_sp.png)no-repeat;
      height: 280px;
      background-size: contain;
    }
  }

  /*---プログラムの特徴---*/
  .page_feature{
    & .page_main_visual{
      background: url(../img/program/key_img_sp.png)no-repeat;
      height: 280px;
      background-size: contain;
    }
    & .inner{
      & .box ol{
        list-style: decimal;
        margin: 0px 0px 0px 20px;
      }
      & .box ol li{
        line-height: 2;
        margin-bottom: 40px;
      }
      & .box ol.sm-space li{
        line-height: 2;
        margin-bottom: 0px;
      }
      & .img-holder{
        margin-top: 40px;
        margin-bottom: 84px;
      }
    }
  }

  /*---プログラムの概要---*/

  .page_outline{
    & .page_main_visual{
      background: url(../img/program/key_img_sp.png)no-repeat;
      height: 280px;
      background-size: contain;
    }
    & dl{
      display: block;
      width: 100%;
      border: 1px solid #ebebeb;
      & dt, & dd{
        display: block;
        padding: 22px; 
        border-top: 1px solid #ebebeb;
      }
      & dt{
        text-align: center;
      }
    }
    & dl:last-child dd{
       border-bottom: 1px solid #ebebeb;
    }
    & span.divider{
      display: block;
      border-top: 1px solid #ebebeb;
    }
    & span.divider:after{
      content: url('../img/program/icon-arrow-down.png');
      display: block;
      position: relative;
      width:52px;
      margin: 30px auto;
    }
    & ul{
      margin: 0px 0px 0px 20px;
      & li{
        list-style: disc;
       }
     }
  }

  /*--- 研修スケジュール ---*/
   .page_training_schedule{
    & .page_main_visual{
      background: url(../img/program/key_img_sp.png)no-repeat;
      height: 280px;
      background-size: contain;
    }
  }

  /*--- 研修体制 ---*/
   .page_system{
    & .page_main_visual{
      background: url(../img/program/key_img_sp.png)no-repeat;
      height: 280px;
      background-size: contain;
    }
    & section{
      & .img-holder{
        margin-top: 44px;
      }
      & img{
        margin-bottom: 40px;
      }
    }
  }

   /*--- 研修施設 ---*/
   .page_facilities{
      & .page_main_visual{
        background: url(../img/program/key_img_sp.png)no-repeat;
        height: 280px;
        background-size: contain;
      }
      & section{
        & p{
          margin-top: 28px;
        }
      }
     
     & table{
       border-bottom: solid 1px #aaaaaa;
       & th{
         width: 10%;
         background: #e6f4ff;
         font-size: 18px;
         text-align: center;
         vertical-align: middle;
       }
       & td{
         vertical-align: middle;
       }
       & th.bunya{
         padding: 2%;
         vertical-align: middle;
         border-bottom: solid 2px #aaaaaa;
         border-right: solid 1px #aaaaaa;
       }
       & th.name{
         padding: 2%;
         width: 62%;
         border-bottom: solid 1px #aaaaaa;
       }
       & th.kikan{
         padding: 2%;
         border-bottom: solid 2px #aaaaaa;
         border-left: solid 1px #aaaaaa;
       }
       & .bottom_title{
         & td{
           background: #e6f4ff;
           text-align: center;
           border-bottom: solid 2px #0071c5;
           padding: 2%;
         }
         & .minato{
           padding: 2%;
           border-right: solid 1px #aaaaaa;
         }
       }
       & .all{
         border-bottom: dotted 1px #aaaaaa;
         & th{
           border-right: solid 1px #aaaaaa;
         }
         & td{
           padding: 2%;
         }
         & .hospital_name{
           border-right: solid 1px #aaaaaa;
         }
         & a{
           color: #000;
         }
         & a:hover{
           color: #0071c5;
         }
       }
       & .style01{
         & .field{
           padding: 2%;
           background: #f9f1e5;
         }
       }
       & .style02{
         & .field{
           padding: 2%;
           background: #f0e4d3;
         }
         & td{
           background: #ededed;
         }
       }
       & .choice{
         width: 50px;
         border-right: solid 1px #fff!important;
       }
     }
    }

    /*--- 研修医データ ---*/
   .page_data{
      & .page_main_visual{
        background: url(../img/program/key_img_sp.png)no-repeat;
        height: 280px;
        background-size: contain;
      }
      & section{
        h3{
          margin-bottom: 40px;
        }
      }
    }

    /*--- 研修修了後の進路 ---*/
   .page_course{
      & .page_main_visual{
        background: url(../img/program/key_img_sp.png)no-repeat;
        height: 280px;
        background-size: contain;
      }
      & section{
        h3{
          margin-bottom: 40px;
        }
      }
    }
}
