/*-------------------------------------------

モジュール

-------------------------------------------*/
.body_module{

  .page_main_visual {
    background: url(../img/traning/key_img_pc.png) no-repeat;
    height: 316px;
    background-size: cover;
  }
  .menu_module {
    background-color: #ededed;
    padding: 16px 0;
  } 
  .menu_module .inner {
    margin-top: 0;
  }
  .menu_module ul {
    text-align: center;
  }    

  .module_title{
    font-size: 25px;
    padding: 10px;
    background: #f6b37f;
    color: #000;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  .menu_module ul li {
    display: inline-block;
    border-right: 1px solid #000;
    padding: 0 37px;
  }
  .menu_module a {
    color: #000;
  }
  .menu_module a:hover {
    color: #0071c5;
  }
  .menu_module ul li:last-child {
    border: none;
  }

.module_box_title{
  font-size: 20px;
  color: #ff0000;
  border-bottom: solid 1px #ff0000;
  padding-bottom: 10px;
  margin-top: 50px;
  margin-bottom: 20px;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 25px;
}
.center_box{
  text-align: center;
}
/*-------------------------------------------
title
-------------------------------------------*/
.title_large{
  font-size: 25px;
  margin-bottom: 30px;
}

.title_midium{
  font-size: 20px;
  margin-bottom: 30px;
}
.title_midium_style02{
  margin-bottom: 0;
}
.sub_title{
  margin-bottom: 30px;
}
.text_center{
  text-align: center;
}
.text_right{
  text-align: right;
}

/*-------------------------------------------
list
-------------------------------------------*/
.dot li{
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 20px;
}
.nomal li{
  list-style: decimal;
  list-style-position: outside;
  margin-left: 20px;
}
.kakko li{
  list-style: none;
  text-indent: -20px;
  margin-left: 20px;
}
/*-------------------------------------------
layout
-------------------------------------------*/
.layout_R .category {
  border-top: solid 1px #000;
}
.layout_R .category li {
  border-bottom: dotted 1px #000;
  padding: 10px 0 10px 15px;
}
.layout_R .category li:hover {
    background: url(../img/common/migi_blue_icon.png) no-repeat left;
    position: relative;
}
.layout_R .category li a {
  color: #000;
  display: block;
}
/*-------------------------------------------
blog
-------------------------------------------*/
.blog_cnt {
  padding-top: 40px;
}
.blog_cnt .date {
  float: left;
  margin-right: 20px;
  font-size: 18px;
}
.blog_cnt .category {
  padding: 3px 30px;
  border-radius: 5px;
}
.blog_cnt .blog_title {
  font-size: 25px!important;
  margin: 20px 0!important;
}
.line_more {
  position: relative;
  float: right;
  background-color: #fff;
  z-index: 100;
  bottom: 15px;
  padding-left: 12px;
}
.line_more a {
  color: #000;
}
.blog_cnt .blog_text {
  margin-top: 20px;
  border-bottom: solid 1px #000;
  padding-bottom: 40px;
}
.cercle_icon_kuro a {
  background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
  height: 22px;
  padding-right: 30px;
}
.name_box {
  margin-bottom: 50px;
}
.category_name {
  font-size: 25px;
  border-bottom: solid 1px #000;
}
/*-------------------------------------------
message
-------------------------------------------*/
.doctor_cnt {
  margin-bottom: 100px;
}
.doctor_cnt .doctor {
  float: left;
  width: 258px;
  margin-left: 21px;
  margin-right: 21px;
}
.doctor_cnt .doctor a {
    color: #000;
}
.doctor_cnt .doctor_img img {
  vertical-align: bottom;
  display: block;
  margin: 0 auto;
}
.doctor_cnt .doctor_text_box {
  background: #fff;
  border: solid 1px #cccccc;
  padding: 10px;
}
.doctor_cnt .doctor_text_box .text {
  min-height: 100px;
}
.doctor_cnt .doctor_text_box .name {
  padding-top: 20px;
  text-align: center;
}


/*-------------------------------------------
お申し込み・お問い合わせ
-------------------------------------------*/
.contactform{
  margin-top: 30px;
	.min {
		font-size: 14px;
	}
  & .form_cnt{
    border-bottom: solid 1px #000;
    padding: 40px 0;
    & .group{
      padding-bottom: 20px;
    }
    & .category_neme{
      font-size: 20px;
      display: block;
      padding: 0 0 10px;
    }
    & .category_neme_ctrl{
      padding: 30px 0 0;
    }
    & .form-control{
      width:90%;
      height: 30px;
      padding: 6px 12px;
    }
    & .ctrl{
      float: left;
      margin-right: 20px;
    }
  }
  & .date{
    float: left;
    margin-right: 10px;
  }
  & .date_text_ctrl{
    margin-top: -4px;
    float: left;
    margin-right: 10px;
  }
  & .checkbox_ctrl{
    margin-right: 20px;
    float: left;
  }
  & .textarea_ctrl{
    width: 100%;
    height: 140px;
  }
  & .bottom_box{
    margin: 70px 0 0;
  }
  & .agree_cnt{
    text-align: center;
    margin: 0 0 20px;
  }
  & .btn_std{
    margin: 0 auto;
    text-align: center;
  }
  & .btn_std a:hover{
    color: #0071c5;
    padding: 18px 10px;
    display: block;
    background: url(../img/common/cercle_icon_blue.png) no-repeat right;
    height: 22px;
    text-decoration: none;
    background-position: right 7px bottom 17px;
  }
  .btn_std:hover{
    background: #e6f4ff;
  }
  & .reset_text{
    text-align: center;
    margin-top: 20px;
    & a{
      color: #000;
      background: #e5e5e5;
      padding: 5px 20px;
    }
		& a:hover {
			text-decoration: underline;
		}
  }
}
.thanks_cnt{
  & .inner{
    min-height: 500px;
  }
}
.thanks_title{
  font-size: 35px;
  text-align: center;
}
.thanks_text{
  text-align: center;
  margin-bottom: 70px;
}
.error_message {
	color: #ff0004;
	display: none;
}
.error,
#form_grade_error,
.agree_error {
	color: #FF0004;
	font-size: 15px;
}
.agree_error {
	display: none;
}
.send_button {
	-webkit-appearance: none;
	width: 260px;
	margin: 0 auto;
	background-color: #fff;
	display: block;
	border-radius: 5px;
	line-height: 30px;
	cursor: pointer;
}
.send_button:hover {
	-webkit-appearance: none;
	width: 260px;
	color: #0071c5;
	margin: 0 auto;
	display: block;
	border-radius: 5px;
	line-height: 30px;
	cursor: pointer;
	background: #e6f4ff;
}
.agree_error {
  color: #FF0004;
  font-size: 15px;
}
.contactform .agree_cnt {
  text-align: center;
  margin: 0 0 20px;
}
.defo_link a{
  color: #000;
  text-decoration: underline;
}

/*-------------------------------------------
btn
-------------------------------------------*/
  .links{
    & span{
      border-radius: 5px;
      border: solid 1px #cccccc;
      float: left;
      margin-right: 20px; 
      margin-bottom: 20px;
      & a{
        padding: 3px 30px;
        display: block;
        color: #000;
      }
      & a:after{
        content: url(../img/common/bottom_blue_icon.png);
        padding-left: 7px;
      }
    }
  }
/*-------------------------------------------
pagination
-------------------------------------------*/
.pagination {
  text-align: center;
  padding-bottom: 30px;
}
.pagination .btn_std {
  display: inline-block;
  width: 181px;
}
.pagination .btn_std.prev {
  margin-right: 20px;
}
.pagination .btn_std.next {
  margin-left: 20px;
}
.pagination .btn_std.prev a {
  background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
  background-position: left 10px bottom 17px;
}
.pagination .btn_std a {
  background: none;
}
.pagination .btn_std.next a {
  background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
  background-position: right 10px bottom 17px;
}
.layout_L .pagenation{
  text-align: center; 
  & .prev{
    background: none;
    color: #26323c;
  }
  & .next{
    background: none;
  }
  & .nav-links{
    display: inline-block;
  }
  & .current{
    float: left;
    margin-right: 6px;
    padding: 5px 14px;
    color: #fff;
    background: #26323c;
    display: block;
  }
  & a{
    float: left;
    background: #bfbfbf;
    margin-right: 6px;
    padding: 5px 14px;
    color: #26323c;
    display: block;
  }
  & a:hover{
    color: #fff;
    background: #26323c;
  }
}
  
  .module_fotter .footer_inner .inner{
    margin-top: 0;
  }
  .page_basic{
    & h1{
      font-size: 35px;
      margin-bottom: 30px;
    }
    & h2{
      font-size: 30px;
      margin-bottom: 30px;
    }
    & h3{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    & h4{
      font-weight: bold;
      margin-top: 20px;
    }
    & .right_cnt .inner {
    width: 465px;
    margin: 0;
    }
    & .box{
      padding-bottom: 30px;
      & li{
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px;
      }
      & ul.dot{
        & li{
          list-style-type: disc;
        }
      }
      & ul.kakko{
        & li{
          list-style:none;
          text-indent:-20px;
          margin-left: 20px;
        }
      }
    }
    & .basic_img{
      width: 386px;
      & img{
        margin-bottom: 20px;
      }
    }
  }
  table{
     border-bottom: solid 1px #aaaaaa;
     & th{
       width: 120px;
       background: #e6f4ff;
       font-size: 18px;
       text-align: center;
       vertical-align: middle;
     }
     & td{
       vertical-align: middle;
     }
     & th.bunya{
       padding: 33px 47px;
       vertical-align: middle;
       border-bottom: solid 2px #aaaaaa;
       border-right: solid 1px #aaaaaa;
     }
     & th.name{
       padding: 15px;
       width: 563px;
       border-bottom: solid 1px #aaaaaa;
     }
     & th.kikan{
       padding: 33px 27px;
       border-bottom: solid 2px #aaaaaa;
       border-left: solid 1px #aaaaaa;
     }
     & .bottom_title{
       & td{
         background: #e6f4ff;
         text-align: center;
         border-bottom: solid 2px #0071c5;
         width: 296px;
         padding: 10px 0;
       }
       & .minato{
         border-right: solid 1px #aaaaaa;
       }
     }
     & .all{
       border-bottom: dotted 1px #aaaaaa;
       & th{
         border-right: solid 1px #aaaaaa;
       }
       & td{
         padding: 10px 17px;
       }
       & .hospital_name{
         border-right: solid 1px #aaaaaa;
       }
       & .links{
         border-right: solid 1px #aaaaaa;
       }
       & a{
         color: #000;
       }
       & a:hover{
         color: #0071c5;
       }
     }
     & .style01{
       & .field{
       background: #f9f1e5;
       }
     }
     & .style02{
       & .field{
       background: #f0e4d3;
       }
       & td{
         background: #ededed;
       }
     }
     & .choice{
       width: 50px;
       border-right: solid 1px #aaaaaa;
     }
   }
  
}