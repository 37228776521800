/*-------------------------------------------

研修科目

-------------------------------------------*/
#wrap_traning{
  & h2{
    font-size: 25px;
    margin-bottom: 30px;
  }
  & table{
    & th{
      vertical-align: top;
    }
    & td{
      padding: 0 0 20px 5px;
    }
  }
  & .box{
     padding-bottom: 30px;
  }
  & .links{
      & span{
        width: 46%;
        margin: 0 4px;
        border-radius: 5px;
        border: solid 1px #cccccc;
        float: left;
        margin-bottom: 20px;
        text-align: center;
        & a{
          padding: 15px 23px;
          display: block;
          color: #000;
        }
        & a:after{
          content: url(../img/common/bottom_blue_icon.png);
          padding-left: 7px;
        }
      }
    }
  & .right_cnt{
    float: none;
    clear: both;
  }
  
  /*---index---*/
  & .page_index{
    & .page_main_visual{
      background: url(../img/traning/key_img_sp.png) no-repeat;
      height: 280px;
      background-size: contain;
    }
  }
  /*---基本科目・必修科目---*/
  .page_basic{
    & h1{
      font-size: 25px;
      margin-bottom: 30px;
    }
    & h2{
      font-size: 30px;
      margin-bottom: 30px;
    }
    & h3{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    & h4{
      font-weight: bold;
      margin-top: 10px;
    }
    & .right_cnt .inner {
    margin: 0;
    }
    & .box{
      & li{
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px;
      }
      & ul.dot{
        li{
          list-style-type: disc;
        }
      }
      & ul.kakko{
        & li{
          list-style:none;
          text-indent:-15px;
          margin-left: 15px;
        }
      }
    }
    & .basic_img{
      margin-bottom: 20px;
    }
  }
  .traning_top{
    width: 90%;
    margin: 0 auto;
    clear: both;
    border-bottom: solid 1px #000;
    & .line_more_first {
      bottom: -10px;
    }
  }

  /* page rotate */

  & .page_rotate{
    & h1{
      font-size: 25px;
      margin-bottom: 47px;
    }
    & h2{
      font-size: 20px;
    }
    & section{
      & img{
        margin: 20px 0px;
      }
    }
  }
}