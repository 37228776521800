/*-------------------------------------------

メッセージ

-------------------------------------------*/

body#wrap_message{
  & .links{
    & span{
      border-radius: 5px;
      border: solid 1px #cccccc;
      float: left;
      margin-right: 16px; 
      margin-bottom: 20px;
      & a{
        padding: 10px 32px;
        display: block;
        color: #000;
      }
      & a:after{
        content: url(../img/common/bottom_blue_icon.png);
        padding-left: 7px;
      }
    }
    & span:nth-child(5){
      margin-right: 0;
    }
  }

  .page_message{
    & .page_main_visual{
      background: url(../img/message/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
    & .doctor_cnt{
      margin-bottom: 100px;
      & .doctor{
        float: left;
        width: 258px;
        margin-left: 21px;
        margin-right: 21px;
        & a{
          color: #000;
        }
      }
      .last{
        margin-right: 0;
      }
      & .doctor_img{
				height: 286px;
        text-align: center;
        & img{
          vertical-align: bottom;
          display: block;
          margin: 0 auto;
        }
      }
      & .doctor_text_box{
        background: #fff;
        border: solid 1px #cccccc;
        padding: 10px;
        & .text{
          min-height: 100px;
        }
        & .name{
          padding-top: 20px;
          text-align: center;
        }
      }
    }
    & .name_box{
      margin-bottom: 50px;
    }
    & .category_name{
      font-size: 25px;
      border-bottom: solid 1px #000;
    }
  }

  .page_message_single{
		img { 
			max-width: 100%;
			height: auto;
		}
    & .category{
      font-size: 20px;
      border-bottom: solid 1px #000;
    }
    & section{
      & .left-box{
        float: left;
        width:40%;
        & h1{
          font-size: 50px;
        }
      }
      & .right-box{
        float: right;
      }
    }
    & h3{
      font-size: 20px;
      margin-bottom: 30px;
    }
    & .pagination{
      text-align: center;
      border-bottom: solid 1px #000;
      padding-bottom: 30px;
      & .btn_std{
        display: inline-block;
        width: 181px;
      }
      & .btn_std a{
        background: transparent;
      }
      & .btn_std.prev{
        margin-right: 20px;
      }
      & .btn_std.next{
        margin-left: 20px;
      }
      & .btn_std.prev a{
          background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
          background-position: left 10px bottom 17px;
      }
      & .btn_std.next a{
          background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
          background-position: right 10px bottom 17px;
      }
    }
  }
  #message01,#message02,#message03,#message04,#message05{
    margin-top: -150px;
    padding-top: 150px;
  }
}