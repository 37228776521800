/*-------------------------------------------

募集と採用

-------------------------------------------*/
#wrap_application{
  & h1{
      font-size: 25px;
      margin-bottom: 30px;
    }
  & h2{
    font-size: 25px;
    margin-bottom: 30px;
  }
  & h3{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  & h4{
    font-weight: bold;
    margin-top: 10px;
  }
  & .box{
     padding-bottom: 30px;
  }
  & .btn_std{
    width: 100%;
    margin-top: 0;
  }
  & .bottom_btn{
    & .btn_std{
      width: 90%;
      margin-top: 30px;
    }
  }
  & .right_cnt{
    float: none;
    clear: both;
  }
  
  /*---index---*/
  .page_index{
    & h2{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    & h3{
      font-size: 15px;
      font-weight: bold;
      margin-top: 10px;
    }
    & .right_cnt .inner {
    margin: 0;
    }
    & .box{
      & li{
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px;
      }
      & ul.dot{
        li{
          list-style-type: disc;
        }
      }
      & ul.kakko{
        & li{
          list-style:none;
          text-indent:-20px;
          margin-left: 20px;
        }
      }
    }
    & .basic_img{
      margin-bottom: 20px;
      & img{
        width: 100%;
      }
    }
  }
}