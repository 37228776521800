/*-------------------------------------------

初期・後期振り分けページ

-------------------------------------------*/
#wrap_furiwake{
  @mixin aspect-ratio($width, $height) {
      position: relative;
      &:before{
          display: block;
          content: " ";
          width: 100%;
          padding-top: ($height / $width) * 100%;
      }

      > .content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
      }
  }
  & header{
    & .inner{
      padding: 30px 0px;
      img.sp-on{
        margin: 0 auto;
        display: block;
        max-width: 80%;
      }
    }
  }
  & #content{
    padding-bottom: 0px;
    margin-bottom: 0px;
    & section{
      margin-top: 0px;
      & .inner{
        position: relative;
        width:100%;
        padding: 0px;
        & *{
          box-sizing: border-box;
        }
        & .left_box{
          @include aspect-ratio(8,12); 
          float: none;
          width:100%;
          text-align: center;
          background-size:cover;
          max-height:700px;
          background-position: top center;
          background-image:url('../img/furiwake/furiwake_bg01_sp.png');
        }
        & .right_box{
          @include aspect-ratio(8,12); 
          float: none;
          width:100%;
          text-align: center;
          background-size:cover;
          max-height:700px;
          background-position: top center;
          background-image:url('../img/furiwake/furiwake_bg02_sp.png');
        }
        & div a{
          font-size: 20px;
          color: #000000;
          text-decoration: none;
          padding: 35px 0;
          text-align: center;
          display: block;
          background-color: rgba(255, 255, 255, 0.66);
          border: 1px solid #ebebeb;
          border-right: 0px;
          width: 80%;
          left: 50%;
          position: absolute;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
        & div a:hover{
          color: #0071c5;
          background-color: #e6f4ff;
          opacity: 0.7;
        }
      }
    }
  }
  & footer{
    .footer_menu--type2 {
        display: block;
    }
    & .footer_L{ 
      float: none;
      text-align: center;
    }
    & .footer_R{ 
      display: block;
      float: none;
      text-align: center;
      & ul li a{
        width: 47%;
        display: block;
        float: left;
      }
      & ul li:last-child a{
        float: right;
      }
    }
  }
}


/*-------------------------------------------

お申し込み・お問い合わせ

-------------------------------------------*/

.contactform{
  margin-top: 30px;
	.min {
		font-size: 14px;
	}
  & .form_cnt{
    border-bottom: solid 1px #000;
    padding: 30px 0;
    & .group{
      padding-bottom: 20px;
    }
    & .category_neme{
      font-size: 20px;
      display: block;
      padding: 0 0 10px;
    }
    & .category_neme_ctrl{
      padding: 20px 0 0;
    }
    & .form-control{
      width:90%;
      height: 30px;
      padding: 6px 12px;
    }
    & .ctrl{
      float: left;
      margin-right: 20px;
    }
  }
  & .date{
    float: left;
    margin-right: 10px;
  }
  & .date_text_ctrl{
    margin-top: -4px;
    float: left;
    margin-right: 10px;
  }
  & .checkbox_ctrl{
    margin-right: 20px;
    float: left;
  }
  & .textarea_ctrl{
    width: 100%;
    height: 70px;
  }
  & .bottom_box{
    margin: 50px 0 0;
  }
  & .agree_cnt{
    text-align: center;
    margin: 20px 0;
  }
  & .btn_std{
    margin: 0 auto;
    text-align: center;
    & a{
      background: none;
    }
  }
  & .reset_text{
    text-align: center;
    margin-top: 20px;
    & a{
      color: #000;
      background: #e5e5e5;
      padding: 5px 20px;
    }
		& a:hover {
			text-decoration: underline;
		}
  }
}
.thanks_title{
  font-size: 25px;
  text-align: center;
}
.thanks_text{
  text-align: center;
  margin-bottom: 50px;
}
.error_message {
	color: #ff0004;
	display: none;
}
.error,
#form_grade_error,
.agree_error {
	color: #FF0004;
	font-size: 15px;
}
.agree_error {
	display: none;
}
.send_button {
	-webkit-appearance: none;
	width: 260px;
	margin: 0 auto;
	background-color: #fff;
	display: block;
	border-radius: 5px;
	line-height: 30px;
	cursor: pointer;
}


/*-------------------------------------------

お知らせ

-------------------------------------------*/
.blog_cnt{
  padding-bottom: 30px;
	padding-top: 20px;
  & .date{
    float: left;
    margin-right: 20px;
    font-size: 18px;
  }
  & .category{
    padding: 3px 30px;
    border-radius: 5px;
  }
  & .blog_title{
    font-size: 20px;
    margin: 20px 0;
  }
  & .blog_text{
    margin-top: 20px;
    border-bottom: solid 1px #000;
    padding-bottom: 30px;
  }
}
& .layout_R{
  & .category{
    border-top: solid 1px #000;
    & li{
      border-bottom: dotted 1px #000;
      padding: 10px 0 10px 15px;
      & a{
        color: #000;
        display: block;
      }
    }
    & li:hover{
      background: url(../img/common/migi_blue_icon.png) no-repeat left;
      position: relative;
    }
  }
}
.pagenation{
  width: 100%;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
  text-align: center; 
  & .prev{
    background: none;
    color: #26323c;
    padding: 5px 0;
  }
  & .next{
    background: none;
    padding: 5px 0;
    color: #26323c;
  }
  & .nav-links{
    float: left;
    position: relative;
    left: 50%;
  }
  & .current{
    float: left;
    background: #bfbfbf;
    margin-right: 6px;
    position: relative;
    left: -50%;
    padding: 5px 14px;
    color: #26323c;
    display: block;
    
  }
  & a{
    float: left;
    color: #fff;
    background: #26323c;
    margin-right: 6px;
    position: relative;
    left: -50%;
    padding: 5px 14px;
    display: block;
    
  }
  & a:hover{
      color: #fff;
      background: #26323c;
  }
  & .clear{
    background: none;
  }
}
.page_blog_single {
	img {
		max-width: 100%;
		height: auto;
	}
}

/*-------------------------------------------

個人情報の取扱について

-------------------------------------------*/
#wrap_privacy{
  h1{
    font-size: 25px;
  }
  h2{
    font-size: 20px;
    font-weight: bold;
  }
  & .last_name{
    float: right;
    text-align: right;
    margin-top: 30px;
  }
}



/*-------------------------------------------

エラー画面

-------------------------------------------*/
#wrap_error{
  & .inner_ctrl{
    padding-top: 30px;
    text-align: center;
  }
  & .not_found{
    font-size: 40px;
    color: #666666;
  }
  & .sorry{
    font-size: 25px;
    margin-top: 20px;
  }
  & .btn_std{
    margin-top: 30px;
  }
}

body#wrap_blog{
  /* single */
  .page_blog_single{
    & section{
      margin-top: 50px;
    }
    & .blog_cnt img{
      margin: 0 auto;
      display: block;
      margin-bottom:30px;
    }
    & .pagination{
      text-align: center;
      margin-bottom: 60px;
      & .btn_std{
        display: block;
        width: 181px;
        margin-bottom:12px;
        margin-top: 0px;
      }
      & .btn_std a{
        background: transparent;
      }
      & .btn_std.prev a{
          background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
          background-position: left 10px bottom 17px;
      }
      & .btn_std.next a{
          background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
          background-position: right 10px bottom 17px;
      }
    }
    & .sns_box{
      margin: 34px 0px;
      & ul{
        margin: 0px;
        & li{
          display: inline-block;
          line-height:1;
        }
      }
    }
  }
}

/*-------------------------------------------

アクセス

-------------------------------------------*/
body#wrap_access{
  & section{
    margin-bottom: 60px;
  }
  & h1{
    font-size: 25px;
    margin-bottom: 30px;
  }
  & h2{
    font-size: 20px;
    margin-bottom: 23px;
  }
  & .page_access{
    & .map-box{
      & .left-box{
        & iframe{
          margin: 0 auto;
          display: block;
          margin-bottom: 60px;
        }
      }
      & .right-box{
        width: 100%;
        & .enlarge{
          border-radius: 5px;
          border: solid 1px #cccccc;
          display: block;
          padding: 16px 0px;
          padding-left:55px;
          max-width: 254px;
          width: 100%;
          box-sizing: border-box;
          margin: 20px auto;
          & a{
            color: #000000;
          }
          & a:before{
            content: url(../img/common/bottom_blue_icon.png);
            padding-right: 7px;
          }
        }
      }
    }
  }
}

