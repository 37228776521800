/*-------------------------------------------

研修プログラム

-------------------------------------------*/
#wrap_program{
  & h2{
      font-size: 35px;
      margin-bottom: 30px;
    }
    & table{
      & th{
        vertical-align: top;
      }
      & td{
        padding: 0 0 20px 5px;
      }
    }
    & .box{
       padding-bottom: 30px;
    }
  & .menu_program {
    background-color:#ededed;
    padding: 16px 0;
    & ul {
      text-align: center;
      & li {
        display:inline-block;
        border-right:1px solid #000;
        padding: 0 37px;
        &:last-child {
          border: none;
        }
      }
      &:first-child {
        border-bottom: 1px dashed #a4a4a4;
        padding-bottom: 12px;
        margin-bottom: 12px;
      }
    }
    & a {
      color: #000;
      &:hover {
        color: #0071c5;
      }
    }
  }
  
  
 /*---ご挨拶---*/
  .page_greeting{
    & h1{
      font-size: 35px;
      margin-bottom: 30px;
    }
    & .left_cnt{
      & img{
        width: 270px;
      }
    }
    & .right_cnt{
      & .inner{
        width: 590px;
        margin: 0;
      }
      & .name{
        font-weight: bold;
        font-size: 25px;
        & span{
          font-size: 35px;
        }
      }
      & .title{
        font-size: 25px;
        font-weight: bold;
        margin-top: 35px;
        line-height: 36px;
      }
      & .text_deco{
        margin-top: 25px;
        border-bottom: solid 1px #000;
        padding-bottom: 50px;
      }
    }
    & .box{
      margin-bottom: 50px;
    }
  }
  
  /*---プログラムの目標---*/
  .page_target{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
  }
  /*---学習会・勉強会---*/
  .page_meeting{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
    & .box{
      & img{
        width: 95%;
      }
    }
  }

  /*---プログラムの特徴---*/
  .page_feature{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
    & .inner{
      & .box ol{
        list-style: decimal;
        margin: 0px 0px 0px 20px;
      }
      & .box ol li{
        line-height: 2;
        margin-bottom: 40px;
      }
      & .box ol.sm-space li{
        line-height: 2;
        margin-bottom: 0px;
      }
      & .img-holder{
        margin-top: 40px;
        margin-bottom: 84px;
      }
    }
  }

  /*---プログラムの概要---*/
  .page_outline{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
    & .inner{
     & dl{
      display: table;
      table-layout: fixed;
      width: 100%;
      border: 1px solid #ebebeb;
      border-right: 0px;
      & dt, & dd{
        display: table-cell;
        padding: 30px; 
        border-right: 1px solid #ebebeb;
      }
      & dt{
        width: 30%;
        text-align: center;
        vertical-align: middle;
      }
      & dd{
        width: 70%;
      }
     }
     & span.divider:after{
      content: url('../img/program/icon-arrow-down.png');
      display: block;
      position: relative;
      width:52px;
      margin: 30px auto;
     }
     & ul{
      margin: 0px 0px 0px 2%;
      float: left;
      width: 46%;
      & li{
        list-style: disc;
       }
     }
    }
  }

  /*--- 研修スケジュール ---*/
   .page_training_schedule{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
  }

  /*--- 研修体制 ---*/
   .page_system{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
    & section{
      & p{
        line-height: 2;
      }
      & .img-holder{
        margin-top: 23px;
      }
      & .left-box{
        width: 48%;
        float: left;
      }
      & .right-box{
        width: 48%;
        float: right;
      }
    }
  }

 /*--- 研修施設 ---*/
 .page_facilities{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
    & section{
      & p{
        line-height: 3;
      }
    }
   & table{
     border-bottom: solid 1px #aaaaaa;
     & th{
       width: 120px;
       background: #e6f4ff;
       font-size: 18px;
       text-align: center;
       vertical-align: middle;
     }
     & td{
       vertical-align: middle;
     }
     & th.bunya{
       padding: 33px 47px;
       vertical-align: middle;
       border-bottom: solid 2px #aaaaaa;
       border-right: solid 1px #aaaaaa;
     }
     & th.name{
       padding: 15px;
       width: 563px;
       border-bottom: solid 1px #aaaaaa;
     }
     & th.kikan{
       padding: 33px 27px;
       border-bottom: solid 2px #aaaaaa;
       border-left: solid 1px #aaaaaa;
     }
     & .bottom_title{
       & td{
         background: #e6f4ff;
         text-align: center;
         border-bottom: solid 2px #0071c5;
         width: 296px;
         padding: 10px 0;
       }
       & .minato{
         border-right: solid 1px #aaaaaa;
       }
     }
     & .all{
       border-bottom: dotted 1px #aaaaaa;
       & th{
         border-right: solid 1px #aaaaaa;
       }
       & td{
         padding: 10px 17px;
       }
       & .hospital_name{
         border-right: solid 1px #aaaaaa;
       }
       & .links{
         border-right: solid 1px #aaaaaa;
       }
       & a{
         color: #000;
       }
       & a:hover{
         color: #0071c5;
       }
     }
     & .style01{
       & .field{
       background: #f9f1e5;
       }
     }
     & .style02{
       & .field{
       background: #f0e4d3;
       }
       & td{
         background: #ededed;
       }
     }
     & .choice{
       width: 50px;
       border-right: solid 1px #aaaaaa;
     }
   }
  }

  /*--- 研修医データ ---*/
  .page_data{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
  }

  /*--- 研修修了後の進路 ---*/
  .page_course{
    & .page_main_visual{
      background: url(../img/program/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
    & section{
     .img-holder{
      margin-bottom: 118px;
     }
    }
  }

}


