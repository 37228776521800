/*-------------------------------------------

研修科目

-------------------------------------------*/
#wrap_traning{
  & h2{
    font-size: 35px;
    margin-bottom: 30px;
  }
  & table{
    & th{
      vertical-align: top;
    }
    & td{
      padding: 0 0 20px 5px;
    }
  }
  & .box{
     padding-bottom: 30px;
  }
  & .menu_traning {
    background-color:#ededed;
    padding: 16px 0;
    & .inner{
      margin-top: 0;
    }
    & ul {
      text-align: center;
      & li {
        display:inline-block;
        border-right:1px solid #000;
        padding: 0 37px;
        &:last-child {
          border: none;
        }
      }
    }
    & a {
      color: #000;
      &:hover {
        color: #0071c5;
      }
    }
  }
  & .links{
    & span{
      border-radius: 5px;
      border: solid 1px #cccccc;
      float: left;
      margin-right: 20px; 
      margin-bottom: 20px;
      & a{
        padding: 3px 30px;
        display: block;
        color: #000;
      }
      & a:after{
        content: url(../img/common/bottom_blue_icon.png);
        padding-left: 7px;
      }
    }
  }
  & section{
    & .inner{
      margin-top: 50px;
    }
  }
  /*---index---*/
  .page_index{
    & .page_main_visual{
      background: url(../img/traning/key_img_pc.png) no-repeat;
      height: 316px;
      background-size: cover;
    }
  }
  
  /*---基本科目・必修科目---*/
  /*---選択科目---*/
  .page_basic{
    & h1{
      font-size: 35px;
      margin-bottom: 30px;
    }
    & h2{
      font-size: 30px;
      margin-bottom: 30px;
    }
    & h3{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    & h4{
      font-weight: bold;
      margin-top: 20px;
    }
    & .right_cnt .inner {
    width: 465px;
    margin: 0;
    }
    & .box{
      padding-bottom: 30px;
      & li{
        list-style: decimal;
        list-style-position: outside;
        margin-left: 20px;
      }
      & ul.dot{
        & li{
          list-style-type: disc;
        }
      }
      & ul.kakko{
        & li{
          list-style:none;
          text-indent:-20px;
          margin-left: 20px;
        }
      }
    }
    & .basic_img{
      width: 386px;
      & img{
        margin-bottom: 20px;
      }
    }
    & #basic_01,#basic_02,#basic_03,#basic_04,#basic_05,#basic_06,#basic_07,#basic_08,#select_01,#select_02,#select_03,#select_04,#select_05,#select_06,#select_07,#select_08,#select_09,#select_10{
      margin-top: -150px;
      padding-top: 150px;
    }
  }
  .traning_top{
    width: 900px;
    margin: 0 auto;
    clear: both;
    border-bottom: solid 1px #000;
  }

  /* page rotate */

  & .page_rotate{
    & h1{
      font-size: 35px;
      margin-bottom: 47px;
    }
    & h2{
      font-size: 25px;
    }
  }
}


