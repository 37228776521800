/*-------------------------------------------

sp _index css

-------------------------------------------*/
.contact_btn{
  position: absolute;
  right: 0;
  top:112px;
  z-index: 100;
  float: right;
  background: #fff;
  opacity: 0.8;
  & a{
    color: #0071c5;
    background: url(../img/common/cercle_icon_blue_sp.png) no-repeat top 12px right 20px;
    height: 27px;
    padding: 10px 46px 15px 20px;
    display: block;
  }
}
.main_visual{
  position: relative;
& .slider{
  width: 100%;
  height: 520px;
  & #theTarget{
    & .slide01{
      background: url(../img/top/top_slide01_sp.png) no-repeat;
      background-size: cover;
    }
    & .slide02{
      background: url(../img/top/top_slide02_sp.png) no-repeat;
      background-size: cover;
    }
    & .slide03{
      background: url(../img/top/top_slide03_sp.png) no-repeat;
      background-size: cover;
    }
  }
}
& .header_top{
width: 100%;
position: absolute;
top: 0;
left: 0;
z-index: 100;
& .header_inner{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  & .logo{
    float: left;
  }
}
}
& .key_text{
  position: absolute;
  top: 300px;
  width: 100%;
  z-index: 100;
  & .wrap{
    width: 100%;
    margin: 0 auto;
  }
}
}

#top_content{
& .g_navi{
  position: relative;
  z-index: 10000;
  width: 100%;
  margin: 0 auto;
  height: 65px;
  background: #fff;
  padding-top: 24px;
  & li{
    float: left;
  }
  & li:last-child{
    padding-right: 0;
  }
  & a{
    width: 20px;
    color: #333333;
    text-decoration: none;
    padding-bottom: 10px;
    background: url(../img/top/top_05_pc.png) no-repeat bottom center;
  }
  & a:hover{
    color: #0071c5;
    background: url(../img/top/top_04_pc.png) no-repeat bottom center;
  }
}
  & .fixed{
    position: fixed!important;
    top: 0;
    box-shadow:5px 5px 10px rgba(0, 0, 0, 0.2);
}
  & table{
    th{
      padding: 10px 50px 0 0;
    }
    & td{
      padding: 10px 0 0;
      & a{
        background: url(../img/common/cercle_icon_kuro.png) no-repeat right;
        width:20%;
        height: 22px;
        padding-right: 30px;
      }
    }
    & a{
      color:#000;
    }
  }
}
.inner{
  & section{
    & .content_title{
      & h2{
        font-size: 21px;
        letter-spacing: 3px;
      }
    }
  }
}
.subtitle{
  position: relative;
  background: #fff;
  border: solid 1px #cccccc;
  padding: 30px 69px;
  & .first_title{
    font-size: 21px;
    text-align: center;
  }
  & .second_title{
    text-align: center;
  }
}
.sub_text_box{
  width: 100%;
  margin-top: 20px;
  border-bottom: solid 1px #000;
}
.first_cnt{
  & .inner{
    padding: 20px;
  }
  & .more_text{
    margin: 20px 0;
    border-bottom: solid 1px #000;
    padding-bottom: 35px;
  }
}
.training_cnt{
  & .subtitle_bg{
  content: url(../img/top/top_01_sp.png);
  width: 100%;
  }
  & .subtitle{
    margin-top: -20px;
  }
}
.message_cnt{
  & .subtitle_bg{
  content: url(../img/top/top_02_sp.png);
  width: 100%;
}
  & .subtitle{
    margin-top: -20px;
    padding: 30px 0;
  }
}
.recruitment_cnt{
  position: relative;
  & .subtitle_bg{
  content: url(../img/top/top_03_sp.png);
  width: 100%;
}
  & .subtitle{
    width: 100%;
    position: absolute;
    bottom: 30px;
    background: none;
    border: none;
    padding: 0;
    & .first_title{
    line-height: 50px;
  }
  }
}
.map_cnt{
  width: 100%;
  background-color: #eaece6;
  & .inner{
    padding-top: 10px;
  }
  & .trafic_links{
    margin: 40px 0;
  }
}
.news_cnt{
  & .table_ctrl{
    border-bottom: solid 1px #000;
    padding-bottom: 25px;
  }
  & .news_title{
    font-size: 18px;
    display: block;
  }
  & .table_ctrl{
    padding: 0 0 30px;
  }
}
.trafic_title{
  font-size: 18px;
}