/*-------------------------------------------

初期・後期振り分けページ

-------------------------------------------*/
#wrap_furiwake{
  width: 100%;
  & header{
    & .inner{
      width: 900px;
      margin: 0 auto;
      padding: 20px 0;
      & img.pc-on{
        margin: 0 auto;
        display: block;
      }
    }
  }
  & #content{
    min-width: 1280px;
    width: 100%;
    padding-bottom: 0px;
    & section{
      & .inner{
        position: relative;
        width:100%;
        & *{
          box-sizing: border-box;
        }
        & .left_box{
          max-height:700px;
          height:700px;
          float: left;
          width:50%;
          padding-top:15%;
          text-align: center;
          background-size:cover;
          background-image:url('../img/furiwake/furiwake_bg01_pc.png');
        }
        & .right_box{
          max-height:700px;
          height:700px;
          float: left;
          width:50%;
          padding-top:15%;
          text-align: center;
          background-size:cover;
          background-image:url('../img/furiwake/furiwake_bg02_pc.png');
        }
        & div a{
          font-size: 40px;
          color: #000000;
          text-decoration: none;
          padding: 70px 0;
          text-align: center;
          display: block;
          margin: 0 auto;
          background-color: rgba(255, 255, 255, 0.66);
          border: 1px solid #ebebeb;
          border-right: 0px;
          max-width: 599px;
          width: 100%;
        }
        & div a:hover{
          color: #0071c5;
          background-color: #e6f4ff;
          opacity: 0.7;
        }
      }
    }
  }
  & footer{
    & .footer_L{ 
      float: none;
      text-align: center;
    }
     & .footer_R{ 
      float: none;
      text-align: center;
    }
  }
}


/*-------------------------------------------

お申し込み・お問い合わせ

-------------------------------------------*/
.contactform{
  margin-top: 30px;
	.min {
		font-size: 14px;
	}
  & .form_cnt{
    border-bottom: solid 1px #000;
    padding: 40px 0;
    & .group{
      padding-bottom: 20px;
    }
    & .category_neme{
      font-size: 20px;
      display: block;
      padding: 0 0 10px;
    }
    & .category_neme_ctrl{
      padding: 30px 0 0;
    }
    & .form-control{
      width:90%;
      height: 30px;
      padding: 6px 12px;
    }
    & .ctrl{
      float: left;
      margin-right: 20px;
    }
  }
  & .date{
    float: left;
    margin-right: 10px;
  }
  & .date_text_ctrl{
    margin-top: -4px;
    float: left;
    margin-right: 10px;
  }
  & .checkbox_ctrl{
    margin-right: 20px;
    float: left;
  }
  & .textarea_ctrl{
    width: 100%;
    height: 140px;
  }
  & .bottom_box{
    margin: 70px 0 0;
  }
  & .agree_cnt{
    text-align: center;
    margin: 0 0 20px;
  }
  & .btn_std{
    margin: 0 auto;
    text-align: center;
    & a{
      background: none;
    }
  }
  & .reset_text{
    text-align: center;
    margin-top: 20px;
    & a{
      color: #000;
      background: #e5e5e5;
      padding: 5px 20px;
    }
		& a:hover {
			text-decoration: underline;
		}
  }
}
.thanks_cnt{
  & .inner{
    min-height: 500px;
  }
}
.thanks_title{
  font-size: 35px;
  text-align: center;
}
.thanks_text{
  text-align: center;
  margin-bottom: 70px;
}
.error_message {
	color: #ff0004;
	display: none;
}
.error,
#form_grade_error,
.agree_error {
	color: #FF0004;
	font-size: 15px;
}
.agree_error {
	display: none;
}
.send_button {
	-webkit-appearance: none;
	width: 260px;
	margin: 0 auto;
	background-color: #fff;
	display: block;
	border-radius: 5px;
	line-height: 30px;
	cursor: pointer;
}
.send_button:hover {
	-webkit-appearance: none;
	width: 260px;
	color: #0071c5;
	margin: 0 auto;
	display: block;
	border-radius: 5px;
	line-height: 30px;
	cursor: pointer;
	background: #e6f4ff;
}




/*-------------------------------------------

お知らせ

-------------------------------------------*/
#wrap_blog{
  .blog_cnt{
    padding-top: 40px;
    & .date{
      float: left;
      margin-right: 20px;
      font-size: 18px;
    }
    & .category{
      padding: 3px 30px;
      border-radius: 5px;
    }
    & .blog_title{
      font-size: 25px;
      margin: 20px 0;
    }
    & .blog_text{
      margin-top: 20px;
      border-bottom: solid 1px #000;
      padding-bottom: 40px;
    }
    & .line_more{
      & a{
        color: #000;
      }
      & a:hover{
        color:  #0071c5;
      }
    }
  }
  
  & .layout_R{
    & .category{
    border-top: solid 1px #000;
    & li{
      border-bottom: dotted 1px #000;
      padding: 10px 0 10px 15px;
      & a{
        color: #000;
        display: block;
      }
    }
    & li:hover{
      background: url(../img/common/migi_blue_icon.png) no-repeat left;
      position: relative;
    }
  }
  }
  .pagenation{
    margin-top: 120px; 
    text-align: center; 
    & .prev{
      background: none;
      color: #26323c;
    }
    & .next{
      background: none;
    }
    & .nav-links{
      display: inline-block;
    }
    & .current{
      float: left;
      margin-right: 6px;
      padding: 5px 14px;
      color: #fff;
      background: #26323c;
      display: block;
    }
    & a{
      float: left;
      background: #bfbfbf;
      margin-right: 6px;
      padding: 5px 14px;
      color: #26323c;
      display: block;
    }
    & a:hover{
      color: #fff;
      background: #26323c;
    }
  }

  /* single */

  .page_blog_single{
		img {
			max-width: 100%;
			height: auto;
		}
    & .blog_cnt img{
      margin: 0 auto;
      display: block;
      margin-bottom:30px;
    }
    & .pagination{
      text-align: center;
      & .btn_std{
        display: inline-block;
        width: 181px;
      }
      & .btn_std a{
        background: transparent;
      }
      & .btn_std.prev{
        margin-right: 20px;
      }
      & .btn_std.next{
        margin-left: 20px;
      }
      & .btn_std.prev a{
          background: url(../img/common/cercle_icon_kuro-left.png) no-repeat right;
          background-position: left 10px bottom 17px;
      }
      & .btn_std.next a{
          background: url(../img/common/cercle_icon_kuro-right.png) no-repeat right;
          background-position: right 10px bottom 17px;
      }
    }
    & .sns_box{
      margin-top: 17px;
      margin-bottom: 64px;
      & ul{
        margin: 0px;
        & li{
          display: inline-block;
          line-height:1;
        }
      }
    }
  }

}

/* ----------------access------------------ */

body#wrap_access{
  & section{
    margin-bottom: 60px;
  }
  & h1{
    font-size: 35px;
    margin-bottom: 23px;
  }
  & h2{
    font-size: 25px;
    margin-bottom: 23px;
  }
  & .page_access{
    & .map-box{
      & .left-box{
        float: left;
        width: 60%;
      }
      & .right-box{
        float: right;
        width: 100%;
        max-width: 290px;
        & .enlarge{
          border-radius: 5px;
          border: solid 1px #cccccc;
          display: block;
          padding-left:55px;
          max-width: 254px;
          width: 100%;
          box-sizing: border-box;
          margin: 20px 0px;
          & a{
            color: #000000;
          }
          & a:before{
            content: url(../img/common/bottom_blue_icon.png);
            padding-right: 7px;
          }
        }
      }
    }
  }
  & #subway,#bus{
    margin-top: -150px;
    padding-top: 150px;
  }
}


/*-------------------------------------------

個人情報の取扱について

-------------------------------------------*/
#wrap_privacy{
  h1{
    font-size: 35px;
  }
  h2{
    font-size: 20px;
    font-weight: bold;
  }
  & .last_name{
    float: right;
    text-align: right;
    margin-top: 30px;
  }
}


/*-------------------------------------------

エラー画面

-------------------------------------------*/
#wrap_error{
  & .inner_ctrl{
    padding-top: 50px;
    text-align: center;
  }
  & .not_found{
    font-size: 70px;
    color: #666666;
  }
  & .sorry{
    font-size: 35px;
    margin-top: 20px;
  }
  & .btn_std{
    margin-top: 70px;
  }
}
