/*-------------------------------------------

SP 下層ページ 共通部分

-------------------------------------------*/
.breadcrumb{
  padding: 10px;
  & a{
    color: #000;
  }
  & a:hover{
    color: #0071c5;
  }
}
.page_main_visual{
  position: relative;
  & .page_title_box{
    position: relative;
    top:   80px;
    width: 90%;
    margin: 0 auto;
    border: solid 1px #cccccc;
  }
  & .item_top{
    background: #fff;
    padding: 10px 10px;
  }
  & h1{
    font-size: 24px;
    text-align: center;
  }
  & .english_title{
    text-align: center;
  }
  & .item_bottm{
    background: #26323c;
    color: #fff;
    padding: 10px;
    min-height: 130px;
  }
}
.page_title_only{
  font-size: 25px;
  margin-bottom: 10px;
}
input,textarea{
  border: solid 1px #cccccc;
}
.layout_L{
  width: 100%;
  margin: 0 auto;
}
.layout_R{
  width: 100%;
  margin: 0 auto;
}

/*-------------------------------------------

グロナビアクティブ

-------------------------------------------*/
#wrap_program{
  .gNav{
    li:nth-child(2){
      background: #0071c5;
    }
  }
}

#wrap_traning{
  .gNav{
    li:nth-child(3){
      background: #0071c5;
    }
  }
}

#wrap_message{
  .gNav{
    li:nth-child(4){
      background: #0071c5;
    }
  }
}

#wrap_application{
  .gNav{
    li:nth-child(5){
      background: #0071c5;
    }
  }
}

#wrap_contact{
  .gNav{
    li:nth-child(6){
      background: #0071c5;
    }
  }
}

#wrap_access{
  .gNav{
    li:nth-child(7){
      background: #0071c5;
    }
  }
}


/*-------------------------------------------

h1ボーダー

-------------------------------------------*/

.change-border-color {
  position: relative;
}
.change-border-color::before {
  border-bottom: solid 2px #0071c5;
  width: 30%;
  position: absolute;z-index: 1;bottom: -6px;left: 0;
  content: "";
}
.change-border-color::after {
  border-bottom: solid 2px #cccccc;
  width: 100%;
  position: absolute;bottom: -6px;left: 0;
  content: "";
}
