/*-------------------------------------------

PC 下層ページ 共通部分

-------------------------------------------*/
.body_page{
  margin-top: 150px;
  & .header_inner{
    width: 900px;
    margin: 0 auto;
  }
}
.breadcrumb{
  width: 900px;
  padding: 10px 0;
  margin: 0 auto;
  & a{
    color: #000;
    text-decoration-line: underline;
  }
  & a:hover{
    color: #0071c5;
  }
}
.page_main_visual{
  position: relative;
  & .inner{
    width: 900px;
    margin: 0 auto;
  }
  & .page_title_box{
    position: absolute;
    top: 55px;
    width: 435px;
    border: solid 1px #cccccc;
  }
  & .item_top{
    background: #fff;
    padding: 10px 10px;
  }
  & h1{
    font-size: 30px;
    text-align: center;
  }
  & .english_title{
    text-align: center;
  }
  & .item_bottm{
    background: #26323c;
    color: #fff;
    padding: 10px;
    min-height: 100px;
  }
}
.page_title_only{
  font-size: 35px;
}
input,textarea{
  border: solid 1px #cccccc;
}
.layout_L{
  width: 600px;
  margin: 0 auto;
  float: left;
}
.layout_R{
  width: 200px;
  margin: 0 auto;
  float: right;
}


/*-------------------------------------------

グロナビアクティブ

-------------------------------------------*/

/*-------------------------------------------
研修プログラム
-------------------------------------------*/
#wrap_program{
  & .gNav{
    li:nth-child(1){
      a{
        color: #0071c5;
      }
    }
  }
  /*ご挨拶*/
  & .active_greeting{
    & .megaMenu_top{
      & li:nth-child(1) a{
        color: #0071c5;
      }
    }
  }
  & .active_greeting{
    & .menu_program{
      & .megaMenu_top{
        & li:nth-child(1) a{
          color: #0071c5;
        }
      }
    }
  }
  /*プログラムの目標*/
  & .active_target{
    & .megaMenu_top{
      & li:nth-child(2) a{
        color: #0071c5;
      }
    }
  }
  & .active_target{
    & .menu_program{
      & .megaMenu_top{
        & li:nth-child(2) a{
          color: #0071c5;
        }
      }
    }
  }
  /* プログラムの特徴 */
  & .active_feature{
    & .megaMenu_top{
      & li:nth-child(3) a{
        color: #0071c5;
      }
    }
  }
  & .active_feature{
    & .menu_program{
      & .megaMenu_top{
        & li:nth-child(3) a{
          color: #0071c5;
        }
      }
    }
  }
  /* 研修プログラムの概要 */
  & .active_outline{
    & .megaMenu_top{
      & li:nth-child(4) a{
        color: #0071c5;
      }
    }
  }
  & .active_outline{
    & .menu_program{
      & .megaMenu_top{
        & li:nth-child(4) a{
          color: #0071c5;
        }
      }
    }
  }
  /* 学習会・発表会 */
  & .active_meeting{
    & .megaMenu_top{
      & li:nth-child(5) a{
        color: #0071c5;
      }
    }
  }
  & .active_meeting{
    & .menu_program{
      & .megaMenu_top{
        & li:nth-child(5) a{
          color: #0071c5;
        }
      }
    }
  }
  /* 研修スケジュール */
  & .active_schedule{
    & .megaMenu_bottom{
      & li:nth-child(1) a{
        color: #0071c5;
      }
    }
  }
  & .active_schedule{
    & .menu_program{
      & .megaMenu_bottom{
        & li:nth-child(1) a{
          color: #0071c5;
        }
      }
    }
  }

  /* 研修体制 */
  & .active_system{
    & .megaMenu_bottom{
      & li:nth-child(2) a{
        color: #0071c5;
      }
    }
  }
  & .active_system{
    & .menu_program{
      & .megaMenu_bottom{
        & li:nth-child(2) a{
          color: #0071c5;
        }
      }
    }
  }

  /* 研修施設 */
  & .active_facilities{
    & .megaMenu_bottom{
      & li:nth-child(3) a{
        color: #0071c5;
      }
    }
  }
  & .active_facilities{
    & .menu_program{
      & .megaMenu_bottom{
        & li:nth-child(3) a{
          color: #0071c5;
        }
      }
    }
  }

  /* 研修医データ */
  & .active_data{
    & .megaMenu_bottom{
      & li:nth-child(4) a{
        color: #0071c5;
      }
    }
  }
  & .active_data{
    & .menu_program{
      & .megaMenu_bottom{
        & li:nth-child(4) a{
          color: #0071c5;
        }
      }
    }
  }

  /* 研修修了後の進路 */
  & .active_course{
    & .megaMenu_bottom{
      & li:nth-child(5) a{
        color: #0071c5;
      }
    }
  }
  & .active_course{
    & .menu_program{
      & .megaMenu_bottom{
        & li:nth-child(5) a{
          color: #0071c5;
        }
      }
    }
  }
}

/*-------------------------------------------
研修科目
-------------------------------------------*/
#wrap_traning{
  & .gNav{
    li:nth-child(3){
      a{
        color: #0071c5;
      }
    }
  }
  /*index*/
  & .active_traning_index{
    & ul.megaMenu_single{
      & li:nth-child(1) a{
        color: #0071c5;
      }
    }
  }
  & .active_traning_index{
    & .menu_traning{
      & li:nth-child(1) a{
        color: #0071c5;
      }
    }
  }
  /* ローテート例 */
  & .active_rotate{
    & ul.megaMenu_single{
      & li:nth-child(2) a{
        color: #0071c5;
      }
    }
  }
  & .active_rotate{
    & .menu_traning{
      & li:nth-child(2) a{
        color: #0071c5;
      }
    }
  }
  /*基本科目・必修科目*/
  & .active_basic{
    & ul.megaMenu_single{
      & li:nth-child(3) a{
        color: #0071c5;
      }
    }
  }
  & .active_basic{
    & .menu_traning{
      & li:nth-child(3) a{
        color: #0071c5;
      }
    }
  }
  /*選択科目*/
  & .active_select{
    & ul.megaMenu_single{
      & li:nth-child(4) a{
        color: #0071c5;
      }
    }
  }
  & .active_select{
    & .menu_traning{
      & li:nth-child(4) a{
        color: #0071c5;
      }
    }
  }
}
/*-------------------------------------------
医学生へのメッセージ
-------------------------------------------*/
#wrap_message{
  & .gNav{
    li:nth-child(4){
      a{
        color: #0071c5;
      }
    }
  }
}

/*-------------------------------------------
募集と採用
-------------------------------------------*/
#wrap_application{
  & .gNav{
    li:nth-child(5){
      a{
        color: #0071c5;
      }
    }
  }
  /*病院実習・見学について*/
  & .active_application_index{
    & ul.megaMenu_single{
      & li:nth-child(1) a{
        color: #0071c5;
      }
    }
  }
  & .active_application_index{
    & .menu_application{
      & li:nth-child(1) a{
        color: #0071c5;
      }
    }
  }
  
  /*研修医の処遇*/
  & .active_treatment{
    & ul.megaMenu_single{
      & li:nth-child(2) a{
        color: #0071c5;
      }
    }
  }
  & .active_treatment{
    & .menu_application{
      & li:nth-child(2) a{
        color: #0071c5;
      }
    }
  }
  /*募集要項*/
  & .active_about{
    & ul.megaMenu_single{
      & li:nth-child(3) a{
        color: #0071c5;
      }
    }
  }
  & .active_about{
    & .menu_application{
      & li:nth-child(3) a{
        color: #0071c5;
      }
    }
  }
  /*研修中断について*/
  & .active_interruption{
    & ul.megaMenu_single{
      & li:nth-child(4) a{
        color: #0071c5;
      }
    }
  }
  & .active_interruption{
    & .menu_application{
      & li:nth-child(4) a{
        color: #0071c5;
      }
    }
  }
}

/*-------------------------------------------
お申し込み・お問い合わせ
-------------------------------------------*/
#wrap_contact{
  & .gNav{
    li:nth-child(6){
      a{
        color: #0071c5;
      }
    }
  }
}

/*-------------------------------------------
アクセス
-------------------------------------------*/
#wrap_access{
  & .gNav{
    li:nth-child(7){
      a{
        color: #0071c5;
      }
    }
  }
}



/*-------------------------------------------

h1ボーダー

-------------------------------------------*/
.change-border-color {
  position: relative;
}
.change-border-color::before {
  border-bottom: solid 2px #0071c5;
  width: 15%;
  position: absolute;z-index: 1;bottom: 0;left: 0;
  content: "";
}
.change-border-color::after {
  border-bottom: solid 2px #cccccc;
  width: 100%;
  position: absolute;bottom: 0;left: 0;
  content: "";
}