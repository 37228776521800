//-----mixin-----

//float
@mixin contL($contWidth:auto) {
    width: $contWidth;
    float:left;
}
@mixin contR($contWidth:auto) {
    width: $contWidth;
    float:right;
}

//radius
@mixin rab($rabkado) {
    border-radius: $rabkado;
    -webkit-border-radius: $rabkado;
    -moz-border-radius: $rabkado;
}

//background
@mixin bgimg($bgimgPass,$bgsize:100%,$bgrepeat:no-repeat,$bgpos:center) {
    background-image:url($bgimgPass) ;
    background-size: $bgsize;
    background-repeat: $bgrepeat;
    background-position: $bgpos;
}


/*-----clearfix-----*/
.clearfix {
    *zoom: 1;
    &:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden;
    }
}

/*-----common class-----*/

.mt-5{margin-top:5px;}
.mt-10{margin-top:10px;}
.mt-20{margin-top:20px;}
.mt-30{margin-top:30px;}
.mt-40{margin-top:40px;}

.mb-5{margin-bottom:5px;}
.mb-10{margin-bottom:10px;}
.mb-20{margin-bottom:20px;}
.mb-30{margin-bottom:30px;}
.mb-40{margin-bottom:40px;}

.pt-5{padding-top:5px;}
.pt-10{padding-top:10px;}
.pt-20{padding-top:20px;}
.pt-30{padding-top:30px;}
.pt-40{padding-top:40px;}

.pb-5{padding-bottom:5px;}
.pb-10{padding-bottom:10px;}
.pb-20{padding-bottom:20px;}
.pb-30{padding-bottom:30px;}
.pb-40{padding-bottom:40px;}

.sp-on {
  display:none;
}

.sp-on img {
  display:none;
}

.pc-on {
  display:none;
}

a {
  text-decoration: none;
}

a img {
  transition: 0.3s;
}

a img:hover {
  opacity: 0.8;
  filter: alpha(opacity=70);
  -ms-filter: "alpha(opacity=70)";
}

a:hover {
  text-decoration: underline;
}

.yu-go {
  font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
}

.yu-min {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
}